import { useEffect, useMemo, useState } from "react"
import Spinner from "../../components/Spinner";
import { Button, FormControl, IconButton, MenuItem, Pagination, PaginationItem, Select, Stack, TextField, Tooltip } from "@mui/material";
import { IoAdd, IoAddCircleSharp, IoAddSharp, IoCash, IoChevronDown, IoCloseSharp, IoLockClosed, IoLockOpen, IoSave, IoSearch, IoTime } from "react-icons/io5";
import Swal from 'sweetalert2'
import { CalcularExpiracion, currencyFormat, generateBackgroundColor, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAlternateEmail, MdDelete, MdEdit, MdSupervisorAccount, MdVerifiedUser } from "react-icons/md";
import { FaChessKing, FaClipboard, FaCoins, FaDollarSign, FaPencilAlt, FaPlus, FaPlusCircle, FaTrashAlt, FaUser, FaUserTie, FaWallet } from 'react-icons/fa';
import Wrapper from "./Wrapper";
import { FcPlus } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import UpdateUser from "./UpdateUser";
import { BsInfo } from "react-icons/bs";
import debounce from 'lodash.debounce';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import SpinnerData from "../../components/SpinnerData";
import Spinner2 from "../../components/Spinner2";
import { useDispatch, useSelector } from "react-redux";
import { SET_TO_DEFAULT_RESPONSE, addCash, del, getByPage, contador as getContador, lock, updateVip } from "../../store/features/slices/userSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import { FaBusinessTime, FaLock, FaLockOpen, FaRegCalendarPlus, FaRegUser, FaUserCheck, FaUserClock, FaUserLock, FaUserTag, FaUsersGear } from "react-icons/fa6";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import Modal from "../../components/modal/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import SpinnerButtom from "../../components/SpinnerButtom";
import { RiVipCrownFill } from "react-icons/ri";
import CardVip from "../../components/CardVip";
import { CURRENCY } from "../../utils/Constants";
import CountUp from 'react-countup';
const moment = require("moment");
require("moment/locale/es");

const Users = ({ }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [currentPage, setCurrentPage] = useState(1);
   const [curTime, SetCurTime] = useState(null)
   const [searchTerm, setSearchTerm] = useState('');
   const [limit, setLimit] = useState(30);
   const [openedituser, SetOpenEditUser] = useState({ open: false, userData: null })
   const [usersFilter, setUsersFilter] = useState('')
   const [changeExpirationTimeVip, setChangeExpirationTimeVip] = useState({
      open: false,
      _id: "",
      newexpiration: ""
   })
   const [addcredits, Setaddcredits] = useState({
      open: false,
      _id: "",
      credits: 0,
      email: "",
      currentcredits: 0,
      payment_id: "",
      payment_type: "",
   })

   const {
      usersByPage,
      isMutation,
      contador,
      totalFilter,
      total,
      totalPages,
      adminToken,
      configuration,
      admin
   } = useSelector((state) => state.userReducer);

   useEffect(() => {
      const interval = setInterval(async () => {
         SetCurTime(new Date().toLocaleString());
      }, 1000);
      return () => clearInterval(interval);
   }, [])

   useEffect(() => {
      if (isMutation.success) {
         message(isMutation.message, 'success', 5)
         dispatch(getContador())
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }
      const queryOptions = {
         page: currentPage,
         limit: limit,
         search: searchTerm.trim(),
      }
      const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
      dispatch(getByPage(query))
   }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

   useEffect(() => {
      if (isMutation.updated) {
         message(isMutation.message, 'success', 5)
         if (isMutation.extra.uUserInfo) {
            if (isMutation.extra.action === 'addCash') {
               Socket.emit('uUserInfo', { newData: isMutation.extra.user, action: isMutation.extra.action, amount: isMutation.extra.amount })
            } else if (isMutation.extra.action === 'lockUser') {
               Socket.emit('uUserInfo', { newData: isMutation.extra.user, action: isMutation.extra.action })
            }
         }
         dispatch(SET_TO_DEFAULT_RESPONSE())
         openedituser.open && HandleCloseEditUser()
         addcredits.open && Setaddcredits({ ...addcredits, open: false, _id: "", credits: 0, payment_type: "", email: "", currentcredits: 0, payment_id: "" });
      }
   }, [isMutation.updated]);

   useEffect(() => {
      dispatch(getContador())
   }, []);


   const deleteUser = id => {
      alertaConfirmar('eliminar Usuario', 'Estas seguro que quieres eliminar este usuario? recuerda que se eliminarán todos los datos relacionados a este usuario.', 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(del(id));
         }
      })
   }

   const handleUpdateCash = e => {
      e.preventDefault();
      if (addcredits.credits !== 0) {
         if (addcredits.payment_type !== "" || addcredits.credits < 0) {
            /*if (addcredits.payment_type === 'yape') {
               if (addcredits.payment_id.trim().length > 7) {
                  dispatch(addCash(addcredits));
               } else {
                  message(`N° de operación debe ser mayor o igual a 8 caracteres!`, 'error', 4)
               }
            } else {
               dispatch(addCash(addcredits));
            }*/
            dispatch(addCash(addcredits));
         } else {
            message(`Selecciona como recibiste el pago`, 'error', 4)
         }
      } else {
         message(`no puedes agregar ${addcredits.credits} créditos`, 'error', 4)
      }
   }

   const onSendUpdateExpirationVip = () => {
      dispatch(updateVip({
         _id: changeExpirationTimeVip._id,
         newExpiration: changeExpirationTimeVip.newexpiration,
         actionType: 'UPDATE_EXPIRATION'
      }))
   }

   const suspendedVip = (_id, current) => {
      alertaConfirmar(`${current === "ACTIVE" ? 'SUSPENDER' : "REANUDAR"} VIP`, `Estas seguro que quieres ${current === "ACTIVE" ? 'SUSPENDER' : "REANUDAR"} el vip de este usuario?`, 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(updateVip({
               _id: _id,
               newExpiration: "",
               actionType: 'SUSPENDED_VIP'
            }))
         }
      })
   }

   const lockUnlockUser = (id, locked) => {
      var state = ""
      if (locked) {
         state = "Desbloquear"
      } else {
         state = "Bloquear"
      }

      alertaConfirmar(`${state} Usuario`, `Estas seguro que quieres ${state} este usuario?`, 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(lock({ _id: id }));
         }
      })
   }

   const openAddCash = (_id, email, currentcredits) => {
      Setaddcredits({ ...addcredits, open: true, _id: _id, credits: 0, payment_type: "", email: email, currentcredits: currentcredits, payment_id: "" });
   }


   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const changeHandleSearch = (event) => {
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

   const HandleOpenEditUser = (user) => {
      SetOpenEditUser({ ...openedituser, open: true, userData: user });
   }

   const HandleCloseEditUser = () => {
      SetOpenEditUser({ ...openedituser, open: false, userData: null });
   }

   const handleChange = (e, p) => {
      setCurrentPage(p);
   };

   const HandleChangeFilterUsers = async (state) => {
      setSearchTerm(state)
      setUsersFilter(state)
      setCurrentPage(1);
   }

   const onCopy = (text) => {
      message(text, 'success', 2)
   }

   const onChangeSearchTermClear = e => {
      setSearchTerm("");
      HandleChangeFilterUsers("")
      document.getElementById("search").value = "";
   };

   return (
      <Wrapper>
         <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
            Usuarios
            <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
               {!contador.loading && contador.total}
            </div>
         </div>
         {/* HEADER PAGES */}
         <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
            <div className="!w-9/12">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar usuarios...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
            <div className="!w-3/12 flex items-center justify-end">
               <div className="flex items-center justify-end">
                  <Link to="/admin/create-user" className="flex justify-end">
                     <Tooltip title="registrar usuario" arrow={true}>
                        <Button type="button" className="!bg-green-500" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}>
                           <FaPlus size={45} className="!text-white" />
                        </Button>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </div>

         <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
            <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
               {
                  !isMutation.loading ?
                     <>
                        <div className="hidden md:block">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChange}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="large"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-brand-200 dark:bg-brand-950"
                              />
                           </Stack>
                        </div>

                        <div className="block md:hidden">
                           <Stack spacing={2}>
                              <Pagination
                                 count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                                 page={currentPage}
                                 onChange={handleChange}
                                 variant="text"
                                 color="primary"
                                 shape="rounded"
                                 size="small"
                                 renderItem={(item) => (
                                    <PaginationItem
                                       slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                       {...item}
                                    />
                                 )}
                                 className="bg-brand-200 dark:bg-brand-950"
                              />
                           </Stack>
                        </div>
                     </> : <Spinner />
               }
            </div>

            <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">
               <Tooltip title="Click aquí para filtrar usuarios bloqueados" arrow={true}>
                  <div onClick={() => HandleChangeFilterUsers("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "locked" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-600 p-2 md:p-3 rounded-lg`}>
                     <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.locked : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
               <Tooltip title="Click aquí para filtrar usuarios resellers" arrow={true}>
                  <div onClick={() => HandleChangeFilterUsers("resellers")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "resellers" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-yellow-600 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><FaDollarSign className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.reseller : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
               <Tooltip title="Click aquí para filtrar usuarios VIP" arrow={true}>
                  <div onClick={() => HandleChangeFilterUsers("vip")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "vip" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-yellow-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><RiVipCrownFill className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.vip : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
               <Tooltip title="Click aquí para filtrar todos los usuarios" arrow={true}>
                  <div onClick={() => HandleChangeFilterUsers("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${usersFilter === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdSupervisorAccount className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.total : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>
            </div>
         </div>

         <Modal
            open={openedituser.open}
            onClose={HandleCloseEditUser}
            headerTitle="Editar usuario"
         >
            <div className="flex flex-wrap h-auto">
               <UpdateUser
                  user={openedituser.userData}
                  HandleCloseEditUser={HandleCloseEditUser}
               />
            </div>
         </Modal>

         <Modal
            open={changeExpirationTimeVip.open}
            onClose={() => { setChangeExpirationTimeVip({ ...changeExpirationTimeVip, open: false }) }}
            headerTitle={`Editar expiración de VIP`}
            size='base'
         >
            <div className="relative w-full">
               <div className="w-full md:w-12/12">
                  <div className="w-full md:w-12/12">
                     <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nueva expiración VIP</label>
                     <input autoFocus name="newexpiration" className="custom-style-input"
                        autoComplete="off"
                        value={changeExpirationTimeVip.newexpiration}
                        onChange={(e) => { setChangeExpirationTimeVip({ ...changeExpirationTimeVip, newexpiration: e.target.value }) }}
                        type="datetime-local"
                     />
                  </div>
                  <div className="flex m-auto w-full mt-5">
                     <Button
                        onClick={onSendUpdateExpirationVip}
                        style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                        size='large' variant="contained"
                        disabled={isMutation.uloading ? true : false}
                     >
                        {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                        {isMutation.uloading ? 'Actualizando...' : 'Actualizar VIP'}
                     </Button>
                  </div>
               </div>
            </div>
         </Modal>

         <Modal
            open={addcredits.open}
            onClose={() => Setaddcredits({ ...addcredits, open: false, _id: "", credits: 0, payment_type: "", email: "", currentcredits: 0, payment_id: "" })}
            headerTitle="Recargar créditos"
            size="sm"
         >
            <form className="w-full" onSubmit={handleUpdateCash}>
               <div class="w-full">
                  <div className="mb-6 bg-brand-100 dark:bg-brand-900 rounded-lg p-3 space-y-5">
                     <label for="first_name" class="block uppercase mb-2 text-sm font-semibold text-brand-900 dark:text-white">Usuario</label>
                     <label className="flex items-center bg-white dark:bg-brand-950 rounded-lg p-2 font-bold text-sm text-brand-700 dark:text-brand-300"><FaUser size={24} className="mr-2" /> {addcredits.email}</label>
                     <label for="first_name" class="block uppercase mb-2 text-sm font-semibold text-brand-900 dark:text-white">Créditos actuales</label>
                     <label className={`flex items-center bg-white dark:bg-brand-950 rounded-lg p-2 font-bold text-sm text-brand-700 dark:text-brand-300`}><FaWallet size={24} className="mr-2" /> {currencyFormat(addcredits.currentcredits)}</label>
                     <label for="first_name" class="block uppercase mb-2 text-sm font-semibold text-brand-900 dark:text-white">Créditos después de recargar</label>
                     <label className={`flex items-center bg-white dark:bg-brand-950 rounded-lg p-2 font-bold text-sm transition-all duration-500 ${addcredits.credits >= 0 ? 'text-brand-700 dark:text-brand-300' : 'text-lightError dark:text-darkError'}`}><FaCoins size={24} className="mr-2" /> {addcredits.credits != 0 ? <CountUp start={addcredits.currentcredits} end={addcredits.currentcredits + Number(addcredits.credits)} duration={2} separator="," decimals={2} prefix={`${CURRENCY}`} /> : ' ------ '} </label>
                  </div>

                  <div className="mb-6">
                     <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Cantidad de créditos</label>
                     <input autoFocus name="credits" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={addcredits.credits}
                        onChange={e => Setaddcredits({ ...addcredits, credits: e.target.value })}
                        type="number"
                     />
                  </div>

                  {
                     addcredits.credits > 0 &&
                     <>
                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Como recibiste el pago?</label>
                        <div className="relative mb-6">
                           <FormControl fullWidth>
                              <Select
                                 required
                                 name="payment_type"
                                 className="flex items-center"
                                 variant="outlined"
                                 value={addcredits.payment_type}
                                 onChange={e => Setaddcredits({ ...addcredits, payment_type: e.target.value })}
                              >
                                 {configuration?.pasarelas?.yape?.enabled && <MenuItem value={'yape'} key={'yape'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/yape.webp`} className="mr-2 rounded-full" />Yape</MenuItem>}
                                 {configuration?.pasarelas?.plin?.enabled && <MenuItem value={'plin'} key={'plin'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/plin.webp`} className="mr-2 rounded-full" />Plin</MenuItem>}
                                 <MenuItem value={'stripe'} key={'stripe'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/stripe.webp`} className="mr-2 rounded-full" />Stripe</MenuItem>
                                 {configuration?.pasarelas?.izipay?.enabled && <MenuItem value={'izipay'} key={'izipay'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/izipay.webp`} className="mr-2 rounded-full" />IziPay</MenuItem>}
                                 {configuration?.pasarelas?.mercadopago?.enabled && <MenuItem value={'mercadopago'} key={'mercadopago'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/mercadopago.webp`} className="mr-2 rounded-full" />Mercadopago</MenuItem>}
                                 <MenuItem value={'gift'} key={'gift'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/gift.webp`} className="mr-2 rounded-full" />Fué un regalo</MenuItem>
                                 <MenuItem value={'binance'} key={'binance'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/binance.webp`} className="mr-2 rounded-full" />Binance</MenuItem>
                                 <MenuItem value={'paypal'} key={'paypal'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/paypal.webp`} className="mr-2 rounded-full" />Paypal</MenuItem>
                                 <MenuItem value={'transferencia'} key={'transferencia'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/transferencia.webp`} className="mr-2 rounded-full" />Transferencia</MenuItem>
                                 {configuration?.pasarelas?.pagomovil?.enabled && <MenuItem value={'pagomovil'} key={'pagomovil'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/pagomovil.webp`} className="mr-2 rounded-full" />Pago Movil</MenuItem>}
                                 {configuration?.pasarelas?.nequi?.enabled && <MenuItem value={'nequi'} key={'nequi'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/nequi.webp`} className="mr-2 rounded-full" />Nequi</MenuItem>}
                                 {configuration?.pasarelas?.banamex?.enabled && <MenuItem value={'banamex'} key={'banamex'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/banamex.webp`} className="mr-2 rounded-full" />Banamex</MenuItem>}
                                 {configuration?.pasarelas?.yolopago?.enabled && <MenuItem value={'yolopago'} key={'yolopago'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/yolopago.webp`} className="mr-2 rounded-full" />Yolopago</MenuItem>}
                                 {configuration?.pasarelas?.tigomoney?.enabled && <MenuItem value={'tigomoney'} key={'tigomoney'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/tigomoney.webp`} className="mr-2 rounded-full" />Tigo Money</MenuItem>}
                                 {configuration?.pasarelas?.yasta?.enabled && <MenuItem value={'yasta'} key={'yasta'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/yasta.webp`} className="mr-2 rounded-full" />Yasta</MenuItem>}
                                 {configuration?.pasarelas?.spin?.enabled && <MenuItem value={'spin'} key={'spin'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/spin.webp`} className="mr-2 rounded-full" />Spin</MenuItem>}
                                 {configuration?.pasarelas?.cashapp?.enabled && <MenuItem value={'cashapp'} key={'cashapp'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/cashapp.webp`} className="mr-2 rounded-full" />Cash App</MenuItem>}
                                 {configuration?.pasarelas?.venmo?.enabled && <MenuItem value={'venmo'} key={'venmo'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/venmo.webp`} className="mr-2 rounded-full" />Venmo</MenuItem>}
                                 {configuration?.pasarelas?.zelle?.enabled && <MenuItem value={'zelle'} key={'zelle'}><LazyLoadImage width={25} height={25} src={`/images/paymethods/zelle.webp`} className="mr-2 rounded-full" />Zelle</MenuItem>}

                              </Select>
                           </FormControl>
                        </div>
                     </>
                  }

                  {/*
                     addcredits.payment_type === 'yape' &&
                     <div className="mb-2">
                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Número de operación de yape</label>
                        <input autoFocus name="payment_id" className="custom-style-input"
                           autoComplete="off"
                           maxLength={16}
                           value={addcredits.payment_id}
                           onChange={e => Setaddcredits({ ...addcredits, payment_id: e.target.value })}
                           type="text"
                        />
                     </div>*/
                  }

               </div>
               <div className="w-full">
                  <Button
                     type="submit"
                     style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                     size='large' variant="contained" color={`primary`}
                     disabled={(isMutation.uloading || addcredits.credits < 1) ? true : false}
                  >
                     {isMutation.uloading ? <Spinner /> : <IoCash size={24} className="mr-2" />}
                     {isMutation.uloading ? 'Procesando...' : `Agregar Creditos`}
                  </Button>
               </div>
            </form>
         </Modal >
         <div className="relative w-full">
            {
               !isMutation.loading ? usersByPage.length > 0 ?
                  <div className="flex flex-wrap mt-[295px] md:mt-[320px] lg:mt-[280px]">
                     {usersByPage.map((user) => {
                        var userIsused = false

                        return (
                           <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 ${user.locked && 'bg-gradient-to-r from-[#ea06068c]'} bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-lg animation`} key={user._id}>
                              <div className="absolute -top-8 left-0">
                                 {
                                    (user.vip.type === 'VIP1' && (user?.vip?.state === 'ACTIVE' || user?.vip?.state === 'SUSPENDED') && new Date(user.vip.expirationdate) > new Date()) ?
                                       <>
                                          <div className="relative bg-gradient-to-t from-[#553617] to-[#CD7F32] inline-block text-transparent bg-clip-text text-xl font-bold">
                                             VIP BRONCE
                                             <RiVipCrownFill className="absolute -right-3 -top-3 text-[#CD7F32] rotate-45 opacity-80" />
                                          </div>
                                          <Tooltip title={`${user?.vip?.state === 'ACTIVE' ? 'suspender' : 'reanudar'} VIP`} arrow={true}>
                                             <Button onClick={() => suspendedVip(user?._id, user?.vip?.state)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}
                                                className={`!ml-3 rounded-xl ${user?.vip?.state === 'ACTIVE' ? '!bg-orange-500 hover:!bg-orange-600 active:!bg-orange-700 dark:!bg-orange-400 dark:hover:!bg-orange-300 dark:active:!bg-orange-200' : '!bg-green-500 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:hover:!bg-green-300 dark:active:!bg-green-200'} !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 dark:!text-white`}>
                                                <FaBusinessTime className="w-5 h-5 text-white" />
                                             </Button>
                                          </Tooltip>
                                          <Tooltip title="editar expiracion VIP" arrow={true}>
                                             <Button onClick={() => { setChangeExpirationTimeVip({ ...changeExpirationTimeVip, _id: user?._id, open: true, newexpiration: moment(user?.vip?.expirationdate).format("yyyy-MM-DDTHH:mm") }) }} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="!ml-2 rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                                <FaPencilAlt className="w-5 h-5 text-white" />
                                             </Button>
                                          </Tooltip>
                                          <label className="text-[11px] ml-2 font-normal text-[#CD7F32]">{CalcularExpiracion(user.vip.expirationdate)}</label>
                                       </>
                                       :
                                       (user.vip.type === 'VIP2' && (user?.vip?.state === 'ACTIVE' || user?.vip?.state === 'SUSPENDED') && new Date(user.vip.expirationdate) > new Date()) ?
                                          <>
                                             <div className="relative bg-gradient-to-t from-[#808080] to-[#C0C0C0] inline-block text-transparent bg-clip-text text-xl font-bold">
                                                VIP PLATA
                                                <RiVipCrownFill className="absolute -right-3 -top-3 text-[#C0C0C0] rotate-45 opacity-80" />
                                             </div>
                                             <Tooltip title={`${user?.vip?.state === 'ACTIVE' ? 'suspender' : 'reanudar'} VIP`} arrow={true}>
                                                <Button onClick={() => suspendedVip(user?._id, user?.vip?.state)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}
                                                   className={`!ml-3 rounded-xl ${user?.vip?.state === 'ACTIVE' ? '!bg-orange-500 hover:!bg-orange-600 active:!bg-orange-700 dark:!bg-orange-400 dark:hover:!bg-orange-300 dark:active:!bg-orange-200' : '!bg-green-500 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:hover:!bg-green-300 dark:active:!bg-green-200'} !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 dark:!text-white`}>
                                                   <FaBusinessTime className="w-5 h-5 text-white" />
                                                </Button>
                                             </Tooltip>
                                             <Tooltip title="editar expiracion VIP" arrow={true}>
                                                <Button onClick={() => { setChangeExpirationTimeVip({ ...changeExpirationTimeVip, _id: user?._id, open: true, newexpiration: moment(user?.vip?.expirationdate).format("yyyy-MM-DDTHH:mm") }) }} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="!ml-2 rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                                   <FaPencilAlt className="w-5 h-5 text-white" />
                                                </Button>
                                             </Tooltip>
                                             <label className="text-[11px] ml-2 font-normal text-[#C0C0C0]">{CalcularExpiracion(user.vip.expirationdate)}</label>
                                          </>
                                          : (user.vip.type === 'VIP3' && (user?.vip?.state === 'ACTIVE' || user?.vip?.state === 'SUSPENDED') && new Date(user.vip.expirationdate) > new Date()) &&
                                          <>
                                             <div className="relative bg-gradient-to-t from-[#816e00] to-[#FFD700] inline-block text-transparent bg-clip-text text-xl font-bold">
                                                VIP ORO
                                                <RiVipCrownFill className="absolute -right-5 -top-4 text-[#FFD700] rotate-45 opacity-80" />
                                             </div>
                                             <Tooltip title={`${user?.vip?.state === 'ACTIVE' ? 'suspender' : 'reanudar'} VIP`} arrow={true}>
                                                <Button onClick={() => suspendedVip(user?._id, user?.vip?.state)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}
                                                   className={`!ml-3 rounded-xl ${user?.vip?.state === 'ACTIVE' ? '!bg-orange-500 hover:!bg-orange-600 active:!bg-orange-700 dark:!bg-orange-400 dark:hover:!bg-orange-300 dark:active:!bg-orange-200' : '!bg-green-500 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:hover:!bg-green-300 dark:active:!bg-green-200'} !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 dark:!text-white`}>
                                                   <FaBusinessTime className="w-5 h-5 text-white" />
                                                </Button>
                                             </Tooltip>
                                             <Tooltip title="editar expiracion VIP" arrow={true}>
                                                <Button onClick={() => { setChangeExpirationTimeVip({ ...changeExpirationTimeVip, _id: user?._id, open: true, newexpiration: moment(user?.vip?.expirationdate).format("yyyy-MM-DDTHH:mm") }) }} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="!ml-2 rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                                   <FaPencilAlt className="w-5 h-5 text-white" />
                                                </Button>
                                             </Tooltip>
                                             <label className="text-[11px] ml-2 font-normal text-[#FFD700]">{CalcularExpiracion(user.vip.expirationdate)}</label>
                                          </>
                                 }
                              </div>

                              <div className="flex flex-wrap w-full md:w-full lg:w-[23.33%]">
                                 <div className="flex items-center">
                                    {
                                       user?.photo === 'unknowphoto.webp' ?
                                          <div style={{ background: `${generateBackgroundColor(user?.name)}` }} className="uppercase relative max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] rounded-full flex items-center justify-center text-white text-2xl font-normal z-10">
                                             {user?.name.substr(0, 1)}
                                          </div>
                                          :
                                          <LazyLoadImage
                                             className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px] rounded-full object-cover"
                                             alt={'No image'}
                                             effect="opacity"
                                             src={`/images/photos/${user?.photo}`}
                                          />
                                    }
                                    <div className="grid gap-1 self-center">
                                       <div className="ml-2 flex items-center text-xs">
                                          <MdAlternateEmail size={16} className='mr-[5px]' />
                                          <div className="text-xs w-[calc(100%-55px)] truncate ...">{user.email}</div>
                                          <span className="ml-3">
                                             <Tooltip title="copiar" arrow={true}>
                                                <div>
                                                   <CopyToClipboard text={user.email} onCopy={() => onCopy('correo copiado')}>
                                                      <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                                   </CopyToClipboard>
                                                </div>
                                             </Tooltip>
                                          </span>
                                       </div>
                                       <div className="ml-2 flex items-center text-xs"><FaRegUser size={16} className='mr-[5px]' />{user.name}</div>
                                       <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px] min-w-[16px]' />{setUnixToTimeFormat('created_at', user.created_at)}</div>
                                    </div>
                                 </div>
                              </div>

                              <div className="flex w-full md:w-full lg:w-[30%] flex-wrap">
                                 <div className="w-6/12 grid mt-2 md:mt-2 lg:mt-0">
                                    <div className="flex items-center justify-center text-sm">AUTORIDAD</div>
                                    <div className="flex items-center justify-center">
                                       <span>
                                          {
                                             user.admin ?
                                                <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">
                                                   <FaChessKing size={24} className="mr-2" />ADMINISTRADOR
                                                </div>
                                                : user.reseller ?
                                                   <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                                      <FaCoins size={24} className="mr-2" />RESELLER
                                                   </div>
                                                   :
                                                   <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                                      <FaUserTag size={24} className="mr-2" />CONSUMIDOR
                                                   </div>
                                          }
                                       </span>
                                    </div>
                                 </div>

                                 <div className="w-6/12 grid mt-2 md:mt-2 lg:mt-0">
                                    <div className="flex items-center justify-center text-sm">ESTADO</div>
                                    <div className="flex items-center justify-center">
                                       <span>
                                          {
                                             user.locked ?
                                                <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">
                                                   <FaUserLock size={24} className="mr-2" />Bloqueado
                                                </div>
                                                :
                                                <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                                   <FaUserCheck size={24} className="mr-2" />Activo
                                                </div>
                                          }
                                       </span>
                                    </div>
                                 </div>
                              </div>

                              <div className="flex w-full md:w-full lg:w-4/12 flex-wrap">
                                 <div className="w-6/12 grid mt-2 md:mt-2 lg:mt-0">
                                    <div className="flex items-center justify-center text-sm">BALANCE DE COMPRAS</div>
                                    <div className="flex items-center justify-center">
                                       <span>
                                          <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                             <FaUserTag size={24} className="mr-2" />{currencyFormat(user.credits_used)}
                                          </div>
                                       </span>
                                    </div>
                                 </div>

                                 <div className="flex flex-wrap items-center justify-center md:justify-end w-6/12 mt-2 md:mt-2 lg:mt-0">
                                    <div className="w-full flex items-center justify-center self-center text-sm">
                                       <FaWallet className="mr-2" size={16} /> BALANCE
                                    </div>
                                    <div className="w-full flex items-center justify-center">
                                       <div className="w-full max-w-[165px] flex items-center justify-center text-center bg-gradient-to-r from-green-400 dark:from-green-500 text-brand-900 dark:text-brand-200 rounded-lg p-1">
                                          <div style={{ fontFamily: 'Outfit, sans-serif' }} className="mr-2 text-sm md:text-base w-[calc(150px-56px)] md:w-[calc(160px-56px)] text-green-950 dark:text-green-50">{currencyFormat(user.credits_purchased - user.credits_used)}</div>
                                          <Tooltip title="agregar créditos" arrow={true}>
                                             <Button onClick={() => openAddCash(user._id, user.email, (user.credits_purchased - user.credits_used))} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                                                <FaPlusCircle className="w-5 h-5 text-white" />
                                             </Button>
                                          </Tooltip>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-[13.333%] mt-5 md:mt-5 lg:mt-0">
                                 {
                                    user.locked ?
                                       <Tooltip title="desbloquear" arrow={true}>
                                          <Button onClick={() => lockUnlockUser(user._id, user.locked)} disabled={isMutation.uloading ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                                             {
                                                isMutation.uloading ?
                                                   <SpinnerButtom />
                                                   :
                                                   <FaLockOpen className="w-5 h-5 text-white" />
                                             }
                                          </Button>
                                       </Tooltip>
                                       :
                                       <Tooltip title="bloquear" arrow={true}>
                                          <Button onClick={() => lockUnlockUser(user._id, user.locked)} disabled={user._id === (adminToken && admin.id) || isMutation.uloading ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                             {
                                                isMutation.uloading ?
                                                   <SpinnerButtom />
                                                   :
                                                   <FaLock className="w-5 h-5 text-white" />
                                             }
                                          </Button>
                                       </Tooltip>
                                 }
                                 <Tooltip title="editar" arrow={true}>
                                    <Button onClick={() => HandleOpenEditUser(user)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                       <FaPencilAlt className="w-5 h-5 text-white" />
                                    </Button>
                                 </Tooltip>
                                 <Tooltip title="eliminar" arrow={true}>
                                    <Button onClick={() => deleteUser(user._id)} variant="contained" disabled={(userIsused || user._id === (adminToken && admin.id)) ? true : false} sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                       <FaTrashAlt className="w-5 h-5 text-white" />
                                    </Button>
                                 </Tooltip>
                              </div>
                           </div>
                        )
                     })}
                  </div> : <div className="alert-danger font-semibold mt-[295px] md:mt-[320px] lg:mt-[280px]">Sin resultados en la búsqueda! o no existen usuarios registradas</div> : <div className="mt-[295px] md:mt-[320px] lg:mt-[280px]"><SpinnerData>Cargando usuarios, por favor espere...</SpinnerData></div>}
         </div>
      </Wrapper >
   )
}
export default Users;