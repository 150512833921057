import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { message } from "../../utils/Funciones.js"
import { Button, IconButton, TextField } from "@mui/material"
import { IoEye, IoEyeOff, IoFingerPrint, IoLogIn } from "react-icons/io5"
import { MdMail } from "react-icons/md"
import Spinner from "../../components/Spinner"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { login } from "../../store/features/slices/userSlice"
const moment = require("moment");
require("moment/locale/es");

const AdminLogin = ({ socket }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMutation, configuration, adminToken } = useSelector((state) => state.userReducer);
    const [VerPasswords, SetVerPasswords] = useState(
        {
            password: false,
        });

    const [state, setState] = useState({
        email: '',
        password: ''
    })
    const handleInputs = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const adminLoginFunction = e => {
        state.adminlogin = true
        e.preventDefault();
        if (adminToken) {
            navigate('/admin/dashboard');
        } else {
            dispatch(login(state));
        }
    }

    return (
        <div class="min-h-screen text-brand-900 dark:text-brand-200 flex justify-center">

            <div class="max-w-screen-xl m-0 sm:m-10 bg-brand-50 dark:bg-brand-950 shadow sm:rounded-lg flex justify-center flex-1">
                <div class="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                    <div className="w-8/12 md:w-6/12 m-auto">
                        <LazyLoadImage
                            className="w-mx-auto"
                            alt={'No image logo'}
                            effect="opacity"
                            src={`${localStorage.getItem('color-theme') === 'light' ? '/images/logos/logo-light.png' : '/images/logos/logo-dark.png'}`}
                        />
                    </div>
                    <div class="mt-12 flex flex-col items-center">
                        <div className="w-10/12 md:w-8/12 m-auto">
                            <LazyLoadImage
                                className="w-mx-auto"
                                alt={'No image logo'}
                                effect="opacity"
                                src={`${localStorage.getItem('color-theme') === 'light' ? '/images/logos/admin-login-head-light.png' : '/images/logos/admin-login-head-dark.png'}`}
                            />
                        </div>

                        <form onSubmit={adminLoginFunction} className="w-full">
                            <div class="w-full flex-1 mt-0">
                                <div class="mx-auto max-w-xs">
                                    <div className="w-full relative p-3">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo</label>
                                        <input autoFocus name="email" className="custom-style-input pl-12"
                                            autoComplete="off"
                                            required
                                            onChange={handleInputs}
                                            value={state.email}
                                            type="email"
                                        />
                                        <div class="absolute inset-y-4 mt-7 right-left pl-3 flex items-center text-sm leading-5">
                                            <MdMail size={26} className="text-brand-700 dark:text-brand-300 mr-1" />
                                        </div>
                                    </div>
                                    <div className="w-full relative p-3">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Contraseña</label>
                                        <input name="password" className="custom-style-input pl-12"
                                            autoComplete="off"
                                            required
                                            onChange={handleInputs}
                                            value={state.password}
                                            type={VerPasswords.password ? 'text' : 'password'}
                                        />
                                        <div class="absolute inset-y-4 mt-7 right-left pl-3 flex items-center text-sm leading-5">
                                            <IoFingerPrint size={26} className="text-brand-700 dark:text-brand-300 mr-1" />
                                        </div>
                                        <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                            <IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                                                {VerPasswords.password ? <IoEyeOff className="text-brand-700 dark:text-brand-300" /> : <IoEye className="text-brand-700 dark:text-brand-300" />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="flex m-auto md:w-12/12 p-3 w-full mt-5">
                                        <Button
                                            type="submit"
                                            disabled={isMutation.loading ? true : false}
                                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                            size='large' variant="contained"
                                        >
                                            {isMutation.loading ? <Spinner /> : <IoLogIn size={24} className="mr-2" />}
                                            {isMutation.loading ? 'Ingresando...' : 'Ingresar'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="flex-1 bg-brand-100 dark:bg-brand-900 text-center hidden lg:flex h-full">
                    <div className="flex self-center">
                        <LazyLoadImage
                            className="w-mx-auto w-10/12 md:w-7/12 m-auto"
                            alt={'No image logo'}
                            effect="opacity"
                            src={`/images/logos/admin-login-right.png`}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AdminLogin;