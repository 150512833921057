import { useEffect, useMemo, useState } from "react"
import { MdAlarmOn, MdAllInbox, MdContactMail, MdDelete, MdDeviceHub, MdEdit, MdTimer, MdTimerOff, MdTimeToLeave } from 'react-icons/md';
import { Box, Button, CircularProgress, IconButton, PaginationItem, Stack, TextField, Tooltip } from "@mui/material";
import { CalcularExpiracion, GenerarAlertasSistemaPerfiles, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import Swal from 'sweetalert2'
import { IoAdd, IoAlarm, IoCloseSharp, IoLockClosed } from "react-icons/io5";
import UpdateCuenta from "./UpdateCuenta";
import Wrapper from "./Wrapper";
import debounce from 'lodash.debounce';
import { useNavigate, Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import Pagination from '@mui/material/Pagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SpinnerData from "../../components/SpinnerData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Spinner2 from "../../components/Spinner2";
import { useDispatch, useSelector } from "react-redux";
import { SET_TO_DEFAULT_RESPONSE, getBypage, contador as getContador, deleteCuenta as onDeleteCuenta } from "../../store/features/slices/cuentaSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import Modal from "../../components/modal/Modal";
import { FaMailBulk, FaPencilAlt, FaPlus, FaPlusSquare, FaTrashAlt } from "react-icons/fa";
import Spinner from "../../components/Spinner";
import { FaMobileScreen, FaRegCalendarPlus, FaScreenpal, FaSquarePlus } from "react-icons/fa6";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';

const Cuentas = ({ }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [curTime, SetCurTime] = useState(null)
   const [PasswordEdited, SetPasswordEdited] = useState({ open: false, data: null })
   const [openeditcuenta, SetOpenEditCuenta] = useState({ _id: "", open: false, cuentaData: null })
   const [query, setQuery] = useState("");
   const [verFilterCuentas, SetFilterCuentas] = useState("")

   const [currentPage, setCurrentPage] = useState(1);
   const [searchTerm, setSearchTerm] = useState('');
   const [limit, setLimit] = useState(30);

   const {
      categories,
      subcategories,
      isMutation: isMutationCategories
   } = useSelector((state) => state.categoryReducer);

   const {
      cuentasByPage,
      isMutation,
      contador,
      totalFilter,
      total,
      totalPages
   } = useSelector((state) => state.cuentaReducer);

   useEffect(() => {
      const interval = setInterval(async () => {
         SetCurTime(new Date().toLocaleString());
      }, 1000);
      return () => clearInterval(interval);
   }, [])

   useEffect(() => {
      if (isMutation.success) {
         message(isMutation.message, 'success', 5)
         if (isMutation.extra?.subcategory?.tipoentrega) {
            Socket.emit('uSubCategory', { newData: isMutation.extra.subcategory })
         }
         dispatch(getContador())
         dispatch(SET_TO_DEFAULT_RESPONSE())
      }
      const queryOptions = {
         page: currentPage,
         limit: limit,
         search: searchTerm.trim(),
      }
      const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
      dispatch(getBypage(query))
   }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

   useEffect(() => {
      dispatch(getContador())
   }, []);

   const alertaConfirmar = (titulo, texto, icon, callback) => {
      Swal.fire({
         title: titulo,
         html: texto,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: 'Si',
         denyButtonText: 'No',
         cancelButtonText: 'Cancelar',
         confirmButtonColor: '#2C3E50',
         cancelButtonColor: '#CB4335'
      }).then((result) => {
         if (result.isConfirmed) {
            callback(result.isConfirmed)
         }
      })
   }

   const deleteCuenta = id => {
      alertaConfirmar('ELIMINAR CUENTA', 'Estas seguro que quieres eliminar esta cuenta? recuerda que se eliminarán todos los datos relacionados a esta cuenta.', 'question', (res_alert) => {
         if (res_alert === true) {
            dispatch(onDeleteCuenta(id))
         }
      })
   }

   const handleChangePage = (e, p) => {
      setCurrentPage(p);
   };

   const changeHandleSearch = (event) => {
      SetFilterCuentas('')
      setSearchTerm(event.target.value)
      setCurrentPage(1);
   };

   const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 300), []);

   const HandleOpenEditCuenta = (id) => {
      if (!isMutation.loading) {
         const cuenta = cuentasByPage.find((c) => {
            if (c._id === id) {
               return c
            }
         })
         SetOpenEditCuenta({ ...openeditcuenta, _id: id, open: true, cuentaData: cuenta });
      }
   }

   const HandleCloseEditCuenta = () => {
      SetOpenEditCuenta({ ...openeditcuenta, _id: "", open: false, cuentaData: null });
   }

   const isPasswordEdited = (data) => {
      SetPasswordEdited({ ...PasswordEdited, open: true, data: data })
   }

   const HandleChangeFilterCuentas = (state) => {
      setSearchTerm(state)
      SetFilterCuentas(state)
      setCurrentPage(1)
   }

   const onChangeSearchTermClear = e => {
      setSearchTerm("");
      HandleChangeFilterCuentas("")
      document.getElementById("search").value = "";
   };

   return (
      <Wrapper>
         <Modal
            open={openeditcuenta.open}
            onClose={HandleCloseEditCuenta}
            headerTitle="Editar cuenta"
         >
            <div className="flex flex-wrap h-auto">
               <UpdateCuenta
                  category={categories}
                  subcategory={subcategories}
                  loadingcategory={isMutationCategories.loading}
                  cuenta={openeditcuenta.cuentaData}
                  HandleCloseEditCuenta={HandleCloseEditCuenta}
                  isPasswordEdited={isPasswordEdited}
               />
            </div>
         </Modal>
         {/* HEADER PAGES */}
         <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
            Cuentas
            <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
               {!contador.loading && contador.total}
            </div>
         </div>
         <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
            <div className="relative !w-9/12">
               <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
                  <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
                     <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
                  </InputLeftElement>
                  <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar cuentas...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
                  <InputRightElement className="!mt-[8px] !mr-1">
                     <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                        <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
                     </IconButton>
                  </InputRightElement>
               </InputGroup>
            </div>
            <div className="!w-3/12 flex items-center justify-end">
               <div className="flex items-center justify-end">
                  <Link to="/admin/create-cuenta" className="flex justify-end">
                     <Tooltip title="registrar cuenta" arrow={true}>
                        <Button type="button" className="!bg-green-500" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }}>
                           <FaPlus size={45} className="!text-white" />
                        </Button>
                     </Tooltip>
                  </Link>
               </div>
            </div>
         </div>

         <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
            <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
               {!isMutation.loading ?
                  <>
                     <div className="hidden md:block">
                        <Stack spacing={2}>
                           <Pagination
                              count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                              page={currentPage}
                              onChange={handleChangePage}
                              variant="text"
                              color="primary"
                              shape="rounded"
                              size="large"
                              renderItem={(item) => (
                                 <PaginationItem
                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...item}
                                 />
                              )}
                              className="bg-white dark:bg-brand-900"
                           />
                        </Stack>
                     </div>
                     <div className="block md:hidden">
                        <Stack spacing={2}>
                           <Pagination
                              count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                              page={currentPage}
                              onChange={handleChangePage}
                              variant="text"
                              color="primary"
                              shape="rounded"
                              size="small"
                              renderItem={(item) => (
                                 <PaginationItem
                                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                    {...item}
                                 />
                              )}
                              className="bg-white dark:bg-brand-900"
                           />
                        </Stack>
                     </div>
                  </> : <Spinner />
               }
            </div>
            <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">

               <Tooltip title="Click aquí para filtrar cuentas expiradas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("expired")} className={`flex inline-flex cursor-pointer items-center mb-4 bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none ${verFilterCuentas === "expired" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} text-red-400 p-2 md:p-3 rounded-lg`}>
                     <div className="flex inline-flex items-center"><MdTimerOff className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.expired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>

               <Tooltip title="Click aquí para filtrar cuentas próximas a expirar" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("proxim")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "proxim" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-orange-400 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdTimer className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.proxExpired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>

               <Tooltip title="Click aquí para filtrar cuentas que tienen todos los perfiles expirados" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("allprofilesexpired")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "allprofilesexpired" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-400 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdAlarmOn className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                         bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.profilesExpired : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>

               <Tooltip title="Click aquí para filtrar cuentas bloqueadas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "locked" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.locked : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>

               <Tooltip title="Click aquí para filtrar todas las cuentas" arrow={true}>
                  <div onClick={() => HandleChangeFilterCuentas("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${verFilterCuentas === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-400 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
                     <div className="flex inline-flex items-center"><MdContactMail className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
                     <div className="-mt-12 -mr-4">
                        <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                           <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400"></span>
                           <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                              {!contador.loading ? contador.total : <Spinner2 />}
                           </span>
                        </span>
                     </div>
                  </div>
               </Tooltip>

            </div>
         </div>
         <div className="relative w-full">
            {!isMutation.loading ? cuentasByPage.length > 0 ? (
               <div className="flex flex-wrap mt-[290px] md:mt-[310px] lg:mt-[285px]">
                  {
                     cuentasByPage.map((cuenta) => {

                        return (
                           <div className={`p-4 w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 ${cuenta.locked && 'bg-gradient-to-r from-[#ea06068c]'} bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-10 rounded-lg animation`} key={cuenta._id}>
                              <div className="grid grid-wrap w-full md:w-full lg:w-3/12">
                                 <div className="flex inline-flex items-center">
                                    <LazyLoadImage
                                       className="object-cover w-12 h-12 z-10 rounded-full flex inline-flex"
                                       alt={`No image ${cuenta.subcategory?.name}`}
                                       effect="opacity"
                                       src={`/images/logos/${cuenta.subcategory?.logo}`}
                                    />
                                    <div className="grid gap-1 self-center">
                                       <div className="ml-2 text-sm font-semibold truncate ...">{cuenta.correo}</div>
                                       <div className="ml-2 text-xs">{cuenta.subcategory?.name}</div>
                                       <div className="ml-2 flex items-center text-xs truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', cuenta.created_at)}</div>
                                    </div>
                                 </div>
                              </div>

                              <div className="w-full md:w-6/12 lg:w-3/12 grid mt-3 md:mt-3 lg:mt-0">
                                 <div className="flex items-center justify-center text-sm">EXPIRACIÓN</div>
                                 <div className="flex items-center justify-center">
                                    {
                                       <span>
                                          {CalcularExpiracion(cuenta.expirationdate) === "Caducado" ?

                                             <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />Esta cuenta ah expirado</div>

                                             :
                                             <div>
                                                {(CalcularExpiracion(cuenta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(cuenta.expirationdate).substring(0, 1)) >= 0 &&
                                                   Number(CalcularExpiracion(cuenta.expirationdate).substring(0, 1)) < 3) ?
                                                   <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(cuenta.expirationdate)}</div>
                                                   :
                                                   <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(cuenta.expirationdate)}</div>

                                                }
                                             </div>
                                          }
                                       </span>
                                    }
                                 </div>
                              </div>

                              <div className="w-full md:w-6/12 lg:w-[calc(25%-50px)] grid mt-3 md:mt-3 lg:mt-0">
                                 <div className="flex items-center justify-center text-sm">TIPO DE CUENTA</div>
                                 <div className="flex items-center justify-center">
                                    {cuenta.servicetype === 'completa' ?
                                       <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaScreenpal size={24} className="mr-2" />Cuenta completa</div>
                                       :
                                       <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2"><FaMobileScreen size={24} className="mr-2" />Cuenta compartida</div>
                                    }
                                 </div>
                              </div>

                              <div className="w-full md:w-full lg:w-[calc(25%-50px)] flex flex-wrap mt-3 md:mt-3 lg:mt-0">
                                 <label className="w-full text-center text-sm">ESTADO DE USO</label>
                                 <div className="w-full flex flex-wrap justify-center gap-1">
                                    <div className="w-full flex justify-center">
                                       <Box position="relative" display="inline-flex">
                                          <CircularProgress
                                             variant="determinate"
                                             value={(cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100}
                                             color={`${((cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100 >= 0 && (cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100 < 50) ? 'success' : ((cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100 >= 50 && (cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100 < 100) ? 'warning' : 'error'}`}
                                             size={55}
                                             thickness={6}
                                          />
                                          <Box
                                             top={0}
                                             left={0}
                                             bottom={0}
                                             right={0}
                                             position="absolute"
                                             display="flex"
                                             alignItems="center"
                                             justifyContent="center"
                                          >
                                             <span className="text-[11px] font-bold">{`${((cuenta.estadouso / cuenta.subcategory.maximoperfiles) * 100).toFixed(0)}%`}</span>
                                          </Box>
                                       </Box>
                                    </div>
                                    <div className="w-full flex justify-center text-brand-900 dark:text-brand-100 text-sm">
                                       {cuenta.estadouso + '/' + cuenta.subcategory.maximoperfiles}
                                    </div>
                                 </div>
                              </div>

                              <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-[100px] mt-5 md:mt-5 lg:mt-0">
                                 {
                                    cuenta.locked &&
                                    <IoLockClosed size={32} className="text-red-500" />
                                 }

                                 <Tooltip title="editar" arrow={true}>
                                    <Button onClick={() => HandleOpenEditCuenta(cuenta._id)} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                                       <FaPencilAlt className="w-5 h-5 text-white" />
                                    </Button>
                                 </Tooltip>

                                 <Tooltip title="eliminar" arrow={true}>
                                    <Button onClick={() => deleteCuenta(cuenta._id)} variant="contained" disabled={cuenta.estadouso > 0 ? true : false} sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                       <FaTrashAlt className="w-5 h-5 text-white" />
                                    </Button>
                                 </Tooltip>
                              </div>
                           </div>
                        )
                     })
                  }
               </div>) : (<div className="alert-danger font-semibold mt-[310px]">Sin resultados en la búsqueda! o no existen cuentas registradas</div>) : (<div className="mt-[310px]"><SpinnerData>Cargando cuentas, por favor espere...</SpinnerData></div>)}
         </div>
      </Wrapper>
   )
}
export default Cuentas;