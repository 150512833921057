import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
import { BACKEND } from '../../../utils/Constants';
const API_URL = `${BACKEND}/api/gcventa/`;

const create = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const completarPedido = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const rechazarPedido = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getAll = async () => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `ventasbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getByPageByUserId = async (query) => {
    const response = await axios.get(API_URL + `ventasbyuserId${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const del = async (id, type) => {
    const response = await axios.delete(API_URL + 'venta/' + id + '/' + type, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const gcventaService = {
    create,
    completarPedido,
    rechazarPedido,
    getAll,
    getBypage,
    getByPageByUserId,
    contador,
    del,
};

export default gcventaService;
