import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ImagesPreview = ({ url, heading }) => {
    return (
        <div className="">
            {url &&
                <div className="">
                    <LazyLoadImage
                        className="object-cover rounded-full w-[150px] h-[150px]"
                        alt={`No image`}
                        effect="opacity"
                        src={url}
                    />
                </div>
            }
        </div>
    )
}
export default ImagesPreview;