import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import paymentService from "../services/paymentService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} },
    contador: { pending: 0, rejected: 0, canceled: 0, total: 0, loading: false },
    data: {},
    paymentsByPage: [],
    payments: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const getBypage = createAsyncThunk(
    "payment/getBypage/paymentsbypage",
    async (query, thunkAPI) => {
        try {
            return await paymentService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByPageByUserId = createAsyncThunk(
    "payment/getByPageByUserId/paymentsbyuserId",
    async (query, thunkAPI) => {
        try {
            return await paymentService.getByPageByUserId(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "payment/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await paymentService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createYapePayment = createAsyncThunk(
    "payment/createYapePayment/yape",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.createYapePayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createMercadoPagoPayment = createAsyncThunk(
    "payment/createMercadoPagoPayment/mercadopago",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.createMercadoPagoPayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createPaypalPayment = createAsyncThunk(
    "payment/createPaypalPayment/paypal",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.createPaypalPayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const verifyPaypalPayment = createAsyncThunk(
    "payment/verifyPaypalPayment/verifypaypal",
    async ({ paymentId, PayerID, userId }, thunkAPI) => {
        try {
            return await paymentService.verifyPaypalPayment(paymentId, PayerID, userId);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const anularPayment = createAsyncThunk(
    "payment/anularPayment",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.anularPayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createBinancePayment = createAsyncThunk(
    "payment/createBinancePayment/binance",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.createBinancePayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const createIziPayPayment = createAsyncThunk(
    "payment/createIziPayPayment/izipay",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.createIziPayPayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const verifyIziPayPayment = createAsyncThunk(
    "payment/verifyIziPayPayment/verifyizipay",
    async (formData, thunkAPI) => {
        try {
            return await paymentService.verifyIziPayPayment(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} }
            state.data = {}
        },

        UPDATE_PAYMENT_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.pending = action.payload.totalpaymentsPending;
                state.contador.rejected = action.payload.totalpaymentsRejected;
                state.contador.canceled = action.payload.totalpaymentsCanceled;
                state.contador.total = action.payload.totalpayments;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // GET BY_USER_ID

            .addCase(getByPageByUserId.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByPageByUserId.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.paymentsByPage = action.payload.payments;
                state.totalFilter = action.payload.totalpaymentsFilter;
                state.total = action.payload.totalpayments;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getByPageByUserId.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.paymentsByPage = action.payload.payments;
                state.totalFilter = action.payload.totalpaymentsFilter;
                state.total = action.payload.totalpayments;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE YAPE PAYMENT
            .addCase(createYapePayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createYapePayment.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                state.isMutation.success = true;
            })
            .addCase(createYapePayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // ANULAR PAYMENT
            .addCase(anularPayment.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(anularPayment.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.paymentsByPage.map((payment) => {
                    if (payment._id === action.payload.newData._id) {
                        return {
                            ...payment,
                            status: action.payload.newData.status,
                            observations: action.payload.newData.observations
                        }
                    }
                    return payment
                })
                state.contador.canceled += 1
                state.paymentsByPage = newState
                state.isMutation.extra.user = action.payload.user
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(anularPayment.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // CREATE MERCADOPAGO PAYMENT
            .addCase(createMercadoPagoPayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createMercadoPagoPayment.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.extra.type = 'mercadopago'
                state.data = action.payload.data
                state.isMutation.success = true;
            })
            .addCase(createMercadoPagoPayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE PAYPAL PAYMENT
            .addCase(createPaypalPayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createPaypalPayment.fulfilled, (state, action) => {
                window.location.href = action.payload;
                state.isMutation.loading = false;
            })
            .addCase(createPaypalPayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })


            // VERIFY PAYPAL PAYMENT
            .addCase(verifyPaypalPayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(verifyPaypalPayment.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.type = 'paypal_success';
                state.isMutation.extra.amount = action.payload.amount;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })
            .addCase(verifyPaypalPayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE BINANCE PAYMENT
            .addCase(createBinancePayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createBinancePayment.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.extra.type = 'binance'
                state.data = action.payload.data
                state.isMutation.success = true;
            })
            .addCase(createBinancePayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE IZIPAY PAYMENT
            .addCase(createIziPayPayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(createIziPayPayment.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.extra.type = 'izipay'
                state.data = action.payload.data
                state.isMutation.success = true;
            })
            .addCase(createIziPayPayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CREATE IZIPAY PAYMENT
            .addCase(verifyIziPayPayment.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(verifyIziPayPayment.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.extra.type = 'izipay_success'
                state.isMutation.extra.amount = action.payload.amount
                state.isMutation.success = true;
            })
            .addCase(verifyIziPayPayment.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_PAYMENT_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE } = paymentSlice.actions;

export default paymentSlice.reducer;

