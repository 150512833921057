import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ventaService from "../services/ventaService";
import { message } from "../../../utils/Funciones";

const initialState = {
    isMutation: { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} },
    contador: { expired: 0, proxExpired: 0, pending: 0, locked: 0, rejected: 0, suspended: 0, total: 0, loading: false },
    ventasByPage: [],
    ventasByCorreoId: [],
    ventasAllExpired: false,
    ventas: [],
    totalFilter: 0,
    total: 0,
    currentPage: 1,
    totalPages: 1,
};

export const create = createAsyncThunk(
    "venta/create",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.create(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const completarPedido = createAsyncThunk(
    "venta/completarpedido",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.completarPedido(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const transferirPedido = createAsyncThunk(
    "venta/transferirPedido/transferirpedido",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.transferirPedido(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const reembolsarPedido = createAsyncThunk(
    "venta/reembolsarPedido/reembolsarpedido",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.reembolsarPedido(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const renew = createAsyncThunk(
    "venta/renew/renew",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.renew(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const suspendedById = createAsyncThunk(
    "venta/suspendedById/suspended",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.suspendedById(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const bloquearVentasByCorreoId = createAsyncThunk(
    "venta/bloquearVentasByCorreoId/bloquearbycorreoid",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.bloquearVentasByCorreoId(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAll = createAsyncThunk(
    "venta/getAll",
    async (_, thunkAPI) => {
        try {
            return await ventaService.getAll();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getBypage = createAsyncThunk(
    "venta/getBypage/ventabypage",
    async (query, thunkAPI) => {
        try {
            return await ventaService.getBypage(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByPageByUserId = createAsyncThunk(
    "venta/getByPageByUserId/ventasbyuserId",
    async (query, thunkAPI) => {
        try {
            return await ventaService.getByPageByUserId(query);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getByCorreoId = createAsyncThunk(
    "venta/getByCorreoId/ventasbycorreoid",
    async (correoid, thunkAPI) => {
        try {
            return await ventaService.getByCorreoId(correoid);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const contador = createAsyncThunk(
    "venta/contador/contador",
    async (_, thunkAPI) => {
        try {
            return await ventaService.contador();
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const update = createAsyncThunk(
    "venta/update/venta",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.update(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const changeAutorenewService = createAsyncThunk(
    "venta/changeAutorenewService/autorenew",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.changeAutorenewService(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const rejectedOrLockVenta = createAsyncThunk(
    "venta/rejectedOrLockVenta/rejectedorlockventa",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.rejectedOrLockVenta(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const updateExpirationService = createAsyncThunk(
    "venta/updateExpirationService/updateexpiration",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.updateExpirationService(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const completarPedidoConEmail = createAsyncThunk(
    "venta/completarPedidoConEmail/completarpedidoconemail",
    async (formData, thunkAPI) => {
        try {
            return await ventaService.completarPedidoConEmail(formData);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const del = createAsyncThunk(
    "venta/del/venta",
    async (data, thunkAPI) => {
        try {
            return await ventaService.del(data.id, data.type);
        } catch (error) {
            error.response.data.errors.map(err => {
                message(err.message, 'error', 4)
            })
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const ventaSlice = createSlice({
    name: "venta",
    initialState,
    reducers: {
        SET_TO_DEFAULT_RESPONSE(state, action) {
            state.isMutation = { success: false, error: false, loading: false, message: '', updated: false, uloading: false, extra: {} }
        },

        UPDATE_VENTA_FROM_SOCKET(state, action) {

        },
    },
    extraReducers: (builder) => {
        builder

            // CREATE
            .addCase(create.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(create.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                state.isMutation.extra.newDataSubcategory = action.payload.callback.newData;
                state.isMutation.extra.newHistory = action.payload.newHistory;
                state.isMutation.extra.amount = action.payload.callback.amount;
                state.isMutation.success = true;
            })
            .addCase(create.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // COMPLETAR PEDIDO
            .addCase(completarPedido.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(completarPedido.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.contador.pending -= 1
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(completarPedido.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })


            // TRANSFERIR PEDIDO
            .addCase(transferirPedido.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(transferirPedido.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(transferirPedido.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // REEMBOLSAR PEDIDO
            .addCase(reembolsarPedido.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(reembolsarPedido.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.isMutation.message = action.payload.message;

                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })

                state.ventasByPage = newState;
                state.isMutation.extra.affectSubCategory = action.payload.affectSubCategory;
                state.isMutation.extra.subcategory = action.payload.subcategory;

                if (action.payload.force) {
                    state.contador.locked += 1
                } else {
                    state.contador.expired -= 1
                }

                state.ventasByPage = newState
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(reembolsarPedido.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // RENEW
            .addCase(renew.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(renew.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.isMutation.extra.amount = action.payload.amount;
                state.isMutation.extra.newHistory = action.payload.newHistory;
                state.isMutation.extra.subcategory = action.payload.subcategory;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(renew.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })


            // SUSPENDED BY ID
            .addCase(suspendedById.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(suspendedById.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;

                if (action.payload.from === 'orders') {
                    const newState = state.ventasByPage.map((venta) => {
                        if (venta._id === action.payload.newData._id) {
                            return {
                                ...venta,
                                suspended: action.payload.newData.suspended,
                            }
                        }
                        return venta
                    })

                    state.ventasByPage = newState;
                    if (action.payload.actiontype === 'suspend') {
                        state.contador.suspended += 1
                    } else if (action.payload.actiontype === 'unsuspend') {
                        state.contador.suspended -= 1
                    }

                } else if (action.payload.from === 'cuentas') {
                    const newStateByCorreoId = state.ventasByCorreoId.map((venta) => {
                        if (venta._id === action.payload.newData._id) {
                            return {
                                ...venta,
                                suspended: action.payload.newData.suspended,
                            }
                        }
                        return venta
                    })
                    state.isMutation.extra.suspend = true;
                    state.ventasByCorreoId = newStateByCorreoId;
                }

                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(suspendedById.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // GET ALL

            .addCase(getAll.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventas = action.payload;
            })
            .addCase(getAll.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BYPAGE

            .addCase(getBypage.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getBypage.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getBypage.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // GET BY_USER_ID

            .addCase(getByPageByUserId.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByPageByUserId.fulfilled, (state, action) => {
                state.isMutation.loading = false;
                state.ventasByPage = action.payload.ventas;
                state.totalFilter = action.payload.totalventasFilter;
                state.total = action.payload.totalventas;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getByPageByUserId.rejected, (state, action) => {
                state.isMutation.loading = false;
            })


            // GET BY_CORREO_ID

            .addCase(getByCorreoId.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(getByCorreoId.fulfilled, (state, action) => {
                state.ventasAllExpired = action.payload.allexpired;
                state.ventasByCorreoId = action.payload.ventas;
                state.isMutation.loading = false;
            })
            .addCase(getByCorreoId.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CONTADOR

            .addCase(contador.pending, (state) => {
                state.contador.loading = true
            })
            .addCase(contador.fulfilled, (state, action) => {
                state.contador.expired = action.payload.totalventasExpired;
                state.contador.proxExpired = action.payload.totalventasProxExpired;
                state.contador.pending = action.payload.totalventasPending;
                state.contador.locked = action.payload.totalventasLocked;
                state.contador.rejected = action.payload.totalventasRejected;
                state.contador.total = action.payload.totalventas;
                state.contador.loading = false
            })
            .addCase(contador.rejected, (state, action) => {
                state.contador.loading = false
            })

            // COMPLETAR PEDIDO CON EMAIL

            .addCase(completarPedidoConEmail.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(completarPedidoConEmail.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        return {
                            ...venta,
                            correo: action.payload.newData.correo,
                            expirationdate: action.payload.newData.expirationdate,
                            dataperfiles: action.payload.newData.dataperfiles,
                            state: action.payload.newData.state,
                        }
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.contador.pending -= 1
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(completarPedidoConEmail.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // UPDATE

            .addCase(update.pending, (state) => {
                state.isMutation.loading = false;
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState
                state.isMutation.loading = false;
                state.isMutation.updated = true;
            })
            .addCase(update.rejected, (state, action) => {
                state.isMutation.loading = false;
            })

            // CHANGE AUTORENEW SERVICE

            .addCase(changeAutorenewService.pending, (state) => {
                state.isMutation.uloading = false;
            })
            .addCase(changeAutorenewService.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        return {
                            ...venta,
                            autorenovable: action.payload.newData.autorenovable,
                        }
                    }
                    return venta
                })

                state.ventasByPage = newState
                state.isMutation.extra.autorenovable = action.payload.newData.autorenovable;
                state.isMutation.extra.isActionAutorenew = true;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(changeAutorenewService.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // BLOQUEAR VENTAS BY CORREO ID

            .addCase(bloquearVentasByCorreoId.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(bloquearVentasByCorreoId.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState
                const newStateByCorreoId = state.ventasByCorreoId.filter(fillA => !action.payload.callback.ventas.some(fillB => fillA._id === fillB._id));
                state.ventasByCorreoId = newStateByCorreoId
                state.isMutation.extra.cuenta = action.payload.callback.cuenta;
                state.isMutation.extra.newcontadorcuenta = action.payload.callback.newcontador;
                state.isMutation.extra.profilesinfo = action.payload.callback.cuenta.profilesinfo;
                state.isMutation.extra.affectSubCategory = action.payload.affectSubCategory;
                state.isMutation.extra.subcategory = action.payload.subcategory;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(bloquearVentasByCorreoId.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })


            // UPDATE EXPIRATION

            .addCase(updateExpirationService.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(updateExpirationService.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                const newState = state.ventasByPage.map((venta) => {
                    if (venta._id === action.payload.newData._id) {
                        venta = action.payload.newData
                    }
                    return venta
                })
                state.ventasByPage = newState;
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(updateExpirationService.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // RECHAZAR O BLOQUEAR PEDIDO

            .addCase(rejectedOrLockVenta.pending, (state) => {
                state.isMutation.uloading = true;
            })
            .addCase(rejectedOrLockVenta.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                if (action.payload.actiontype === 'rejected') {
                    const newState = state.ventasByPage.map((venta) => {
                        if (venta._id === action.payload.newData._id) {
                            venta = action.payload.newData
                        }
                        return venta
                    })

                    state.contador.pending -= 1
                    state.contador.rejected += 1
                    state.ventasByPage = newState;

                } else if (action.payload.actiontype === 'locked') {
                    if (action.payload.from === 'orders') {
                        const newState = state.ventasByPage.map((venta) => {
                            if (venta._id === action.payload.newData._id) {
                                venta = action.payload.newData
                            }
                            return venta
                        })

                        state.ventasByPage = newState;
                        state.isMutation.extra.affectSubCategory = action.payload.affectSubCategory;
                        state.isMutation.extra.subcategory = action.payload.subcategory;

                        if (action.payload.force) {
                            state.contador.locked += 1
                        } else {
                            state.contador.expired -= 1
                        }
                    } else if (action.payload.from === 'cuentas') {
                        state.ventasByCorreoId = state.ventasByCorreoId.filter((fil) => fil._id !== action.payload.newData._id)
                        state.isMutation.extra.cuenta = action.payload.cuenta;
                        state.isMutation.extra.newcontadorcuenta = action.payload.newcontadorcuenta;
                        state.isMutation.extra.suspend = false;
                        state.isMutation.extra.profilesinfo = action.payload.cuenta.profilesinfo;
                        state.isMutation.extra.affectSubCategory = action.payload.affectSubCategory;
                        state.isMutation.extra.subcategory = action.payload.subcategory;
                    }
                }
                state.isMutation.uloading = false;
                state.isMutation.updated = true;
            })
            .addCase(rejectedOrLockVenta.rejected, (state, action) => {
                state.isMutation.uloading = false;
            })

            // DELETE

            .addCase(del.pending, (state) => {
                state.isMutation.loading = true;
            })
            .addCase(del.fulfilled, (state, action) => {
                state.isMutation.message = action.payload.message;
                state.isMutation.loading = false;
                state.isMutation.success = true;
            })

            .addCase(del.rejected, (state, action) => {
                state.isMutation.loading = false;
            })
    },
});

export const { UPDATE_VENTA_FROM_SOCKET, SET_TO_DEFAULT_RESPONSE } = ventaSlice.actions;

export default ventaSlice.reducer;

