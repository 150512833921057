import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
import { BACKEND } from '../../../utils/Constants';
const API_URL = `${BACKEND}/api/dashboard/`;

const getRecargasDashboard = async () => {
    const response = await axios.get(API_URL + `recargas`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getVentasDashboard = async () => {
    const response = await axios.get(API_URL + `ventas`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getNewUsersDashboard = async () => {
    const response = await axios.get(API_URL + `newusers`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getBestSellerDashboard = async () => {
    const response = await axios.get(API_URL + `bestseller`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getLast10VentasDashboard = async () => {
    const response = await axios.get(API_URL + `last10ventas`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const dashboardService = {
    getRecargasDashboard,
    getVentasDashboard,
    getNewUsersDashboard,
    getBestSellerDashboard,
    getLast10VentasDashboard
};

export default dashboardService;
