import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
import { BACKEND } from '../../../utils/Constants';
const API_URL = `${BACKEND}/api/giftcard/`;

// Get all categories

const create = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getAll = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `giftcardsbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const del = async (id) => {
    const response = await axios.delete(API_URL + 'giftcard/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const update = async (formData) => {
    const response = await axios.patch(API_URL + 'cuenta', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const giftcardService = {
    create,
    getAll,
    getBypage,
    contador,
    update,
    del
};

export default giftcardService;
