import { useEffect, useState } from "react"
import Spinner from "../../components/Spinner";
import { IoChevronForward, IoCloseCircle, IoReload } from "react-icons/io5";
import Wrapper from "./Wrapper";
import { message, PermiteSoloNumeros } from "../../utils/Funciones";
import { useDispatch, useSelector } from "react-redux";
import ImagesPreviewBanner2 from "../../components/ImagesPreviewBanner2";
import { SET_TO_DEFAULT_RESPONSE, updateConfiguration } from "../../store/features/slices/userSlice";
import { Button, FormControl, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MuiTelInput } from "mui-tel-input";
import Switch from "../../components/switch";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaUpload } from "react-icons/fa6";

const Configuration = ({ socket }) => {
    const dispatch = useDispatch();
    const { isMutation, configuration: configurations } = useSelector((state) => state.userReducer);
    const { isMutation: isMutationCategories, subcategories } = useSelector((state) => state.categoryReducer);
    const [section, setSection] = useState('sistema')
    const [yapeEditor, setYapeEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [plinEditor, setPlinEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [pagomovilEditor, setPagoMovilEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [nequiEditor, setNequiEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [banamexEditor, setBanamexEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [yolopagoEditor, setYoloPagoEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [tigomoneyEditor, setTigoMoneyEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [yastaEditor, setYastaEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [transferenciaEditor, setTransferenciaEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [spinEditor, setSpinEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [cashappEditor, setCashAppEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [venmoEditor, setVenmoEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [zelleEditor, setZelleEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [izipayEditor, setIziPayEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [mercadopagoEditor, setMercadoPagoEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [binanceEditor, setBinanceEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [paypalEditor, setPaypalEditor] = useState(
        () => EditorState.createEmpty(),
    );
    const [stripeEditor, setStripeEditor] = useState(
        () => EditorState.createEmpty(),
    );

    const [configuration, setConfiguration] = useState({
        _id: "",
        appKey: "",
        name: "",
        logo: "",
        slider1: '',
        slider2: '',
        slider3: '',
        slider4: '',
        slider5: '',

        qryape: '',
        qrplin: '',
        qrpagomovil: '',
        qrbanamex: '',
        qrnequi: '',
        qryolopago: '',
        qrtigomoney: '',
        qryasta: '',
        qrtransferencia: '',
        qrspin: '',
        qrcashapp: '',
        qrvenmo: '',
        qrzelle: '',
        qrizipay: '',
        qrmercadopago: '',
        qrbinance: '',
        qrpaypal: '',
        qrstripe: '',

        login: false,
        register: false,
        buyservice: false,
        buycredits: false,
        changepassword: false,
        changeavatar: false,
        maintenance: false,
        howmuchforreseller: 0,
        howmuchtimeforeditpassworddays: 0,
        canrecoverpassword: false,
        sliders: [],
        autodeleteusers: {
            autodelete: true,
            days: 15
        },
        autodeleteventas: {
            autodelete: true,
            days: 30,
            targetIds: []
        },
        autodeletecuentas: {
            autodelete: true,
            days: 30,
            targetIds: []
        },
        social: {
            email: "",
            facebook: "",
            tiktok: "",
            instagram: "",
            telegram: "",
            youtube: "",
            whatsapp: "",
            location: ""
        },
        vip: {
            vip1: {
                price: 12,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            },
            vip2: {
                price: 60,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            },
            vip3: {
                price: 100,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            }
        },
        pasarelas: {
            yape: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            plin: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            pagomovil: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            nequi: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            banamex: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            yolopago: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            tigomoney: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            yasta: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            transferencia: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            spin: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            cashapp: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            venmo: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            zelle: { "enabled": true, "auto": false, "accountdata": { "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}' } },
            izipay: {
                "enabled": true, "auto": true, "public_key": "", "accountdata": {
                    "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    public_key: "", id_tienda: "", clave: "", password: "", clave_hmac_sha_256: ""
                }
            },
            mercadopago: {
                "enabled": true, "auto": true, "accountdata": {
                    "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    api_key: "", secret_key: ""
                }
            },
            binance: {
                "enabled": true, "auto": true, "accountdata": {
                    "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    api_key: "", api_secret: "", merchant_id: ""
                }
            },
            paypal: {
                "enabled": true, "auto": true, "accountdata": {
                    "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    client_id: "", client_secret: ""
                }
            },
            stripe: {
                "enabled": false, "auto": false, "accountdata": {
                    "qr": "", "data": '{"blocks":[{"key":"9vldl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                    api_key: "", secret_key: ""
                }
            }
        }

    })

    useEffect(() => {
        setConfiguration({
            ...configuration,
            _id: configurations._id,
            appKey: configurations.appKey,
            name: configurations.name,
            logo: configurations.logo,
            slider1: configurations.sliders[0]?.url,
            slider2: configurations.sliders[1]?.url,
            slider3: configurations.sliders[2]?.url,
            slider4: configurations.sliders[3]?.url,
            slider5: configurations.sliders[4]?.url,
            login: configurations.login,
            register: configurations.register,
            buyservice: configurations.buyservice,
            buycredits: configurations.buycredits,
            changepassword: configurations.changepassword,
            changeavatar: configurations.changeavatar,
            maintenance: configurations.maintenance,
            howmuchforreseller: configurations.howmuchforreseller,
            howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
            canrecoverpassword: configurations.canrecoverpassword,
            sliders: configurations.sliders,
            autodeleteusers: configurations?.autodeleteusers,
            autodeleteventas: configurations?.autodeleteventas,
            autodeletecuentas: configurations?.autodeletecuentas,
            social: configurations?.social,
            pasarelas: configurations?.pasarelas,
            vip: {
                vip1: {
                    price: configurations?.vip?.vip1?.price,
                    enabled: configurations?.vip?.vip1?.enabled,
                    eslogan: configurations?.vip?.vip1?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip1?.features || []
                },
                vip2: {
                    price: configurations?.vip?.vip2?.price,
                    enabled: configurations?.vip?.vip2?.enabled,
                    eslogan: configurations?.vip?.vip2?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip2?.features || []
                },
                vip3: {
                    price: configurations?.vip?.vip3?.price,
                    enabled: configurations?.vip?.vip3?.enabled,
                    eslogan: configurations?.vip?.vip3?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip3?.features || []
                }
            }
        })
        if (!isMutation.loading && configurations?.pasarelas !== undefined) {
            // YAPE
            const rawContentYape = configurations?.pasarelas?.yape?.accountdata?.data;
            const contentStateYape = convertFromRaw(JSON.parse(rawContentYape));
            const editorStateYape = EditorState.createWithContent(contentStateYape);
            setYapeEditor(editorStateYape)
            // YAPE
            const rawContentPlin = configurations?.pasarelas?.plin?.accountdata?.data;
            const contentStatePlin = convertFromRaw(JSON.parse(rawContentPlin));
            const editorStatePlin = EditorState.createWithContent(contentStatePlin);
            setPlinEditor(editorStatePlin)
            // PAGOMOVIL
            const rawContentPagomovil = configurations?.pasarelas?.pagomovil?.accountdata?.data;
            const contentStatePagomovil = convertFromRaw(JSON.parse(rawContentPagomovil));
            const editorStatePagomovil = EditorState.createWithContent(contentStatePagomovil);
            setPagoMovilEditor(editorStatePagomovil)
            // NEQUI
            const rawContentNequi = configurations?.pasarelas?.nequi?.accountdata?.data;
            const contentStateNequi = convertFromRaw(JSON.parse(rawContentNequi));
            const editorStateNequi = EditorState.createWithContent(contentStateNequi);
            setNequiEditor(editorStateNequi)

            const rawContentBanamex = configurations?.pasarelas?.banamex?.accountdata?.data;
            const contentStateBanamex = convertFromRaw(JSON.parse(rawContentBanamex));
            const editorStateBanamex = EditorState.createWithContent(contentStateBanamex);
            setBanamexEditor(editorStateBanamex);

            const rawContentYolopago = configurations?.pasarelas?.yolopago?.accountdata?.data;
            const contentStateYolopago = convertFromRaw(JSON.parse(rawContentYolopago));
            const editorStateYolopago = EditorState.createWithContent(contentStateYolopago);
            setYoloPagoEditor(editorStateYolopago);

            const rawContentTigomoney = configurations?.pasarelas?.tigomoney?.accountdata?.data;
            const contentStateTigomoney = convertFromRaw(JSON.parse(rawContentTigomoney));
            const editorStateTigomoney = EditorState.createWithContent(contentStateTigomoney);
            setTigoMoneyEditor(editorStateTigomoney);

            const rawContentYasta = configurations?.pasarelas?.yasta?.accountdata?.data;
            const contentStateYasta = convertFromRaw(JSON.parse(rawContentYasta));
            const editorStateYasta = EditorState.createWithContent(contentStateYasta);
            setYastaEditor(editorStateYasta);

            const rawContentTransferencia = configurations?.pasarelas?.transferencia?.accountdata?.data;
            const contentStateTransferencia = convertFromRaw(JSON.parse(rawContentTransferencia));
            const editorStateTransferencia = EditorState.createWithContent(contentStateTransferencia);
            setTransferenciaEditor(editorStateTransferencia);

            const rawContentSpin = configurations?.pasarelas?.spin?.accountdata?.data;
            const contentStateSpin = convertFromRaw(JSON.parse(rawContentSpin));
            const editorStateSpin = EditorState.createWithContent(contentStateSpin);
            setSpinEditor(editorStateSpin);

            const rawContentCashapp = configurations?.pasarelas?.cashapp?.accountdata?.data;
            const contentStateCashapp = convertFromRaw(JSON.parse(rawContentCashapp));
            const editorStateCashapp = EditorState.createWithContent(contentStateCashapp);
            setCashAppEditor(editorStateCashapp);

            const rawContentVenmo = configurations?.pasarelas?.venmo?.accountdata?.data;
            const contentStateVenmo = convertFromRaw(JSON.parse(rawContentVenmo));
            const editorStateVenmo = EditorState.createWithContent(contentStateVenmo);
            setVenmoEditor(editorStateVenmo);

            const rawContentZelle = configurations?.pasarelas?.zelle?.accountdata?.data;
            const contentStateZelle = convertFromRaw(JSON.parse(rawContentZelle));
            const editorStateZelle = EditorState.createWithContent(contentStateZelle);
            setZelleEditor(editorStateZelle);

            const rawContentIzipay = configurations?.pasarelas?.izipay?.accountdata?.data;
            const contentStateIzipay = convertFromRaw(JSON.parse(rawContentIzipay));
            const editorStateIzipay = EditorState.createWithContent(contentStateIzipay);
            setIziPayEditor(editorStateIzipay);

            const rawContentMercadopago = configurations?.pasarelas?.mercadopago?.accountdata?.data;
            const contentStateMercadopago = convertFromRaw(JSON.parse(rawContentMercadopago));
            const editorStateMercadopago = EditorState.createWithContent(contentStateMercadopago);
            setMercadoPagoEditor(editorStateMercadopago);

            const rawContentBinance = configurations?.pasarelas?.binance?.accountdata?.data;
            const contentStateBinance = convertFromRaw(JSON.parse(rawContentBinance));
            const editorStateBinance = EditorState.createWithContent(contentStateBinance);
            setBinanceEditor(editorStateBinance);

            const rawContentPaypal = configurations?.pasarelas?.paypal?.accountdata?.data;
            const contentStatePaypal = convertFromRaw(JSON.parse(rawContentPaypal));
            const editorStatePaypal = EditorState.createWithContent(contentStatePaypal);
            setPaypalEditor(editorStatePaypal);

            const rawContentStripe = configurations?.pasarelas?.stripe?.accountdata?.data;
            const contentStateStripe = convertFromRaw(JSON.parse(rawContentStripe));
            const editorStateStripe = EditorState.createWithContent(contentStateStripe);
            setStripeEditor(editorStateStripe);

        }
    }, [configurations], [configurations?.pasarelas])

    const onChangeHandle = (e) => {
        setConfiguration({ ...configuration, [e.target.name]: e.target.value })
    }

    const [preview, setPreview] = useState({
        logo: '',
        slider1: '',
        slider2: '',
        slider3: '',
        slider4: '',
        slider5: '',
        qryape: '',
        qrplin: '',
        qrpagomovil: '',
        qrbanamex: '',
        qrnequi: '',
        qryolopago: '',
        qrtigomoney: '',
        qryasta: '',
        qrtransferencia: '',
        qrspin: '',
        qrcashapp: '',
        qrvenmo: '',
        qrzelle: '',
        qrizipay: '',
        qrbinance: '',
        qrmercadopago: '',
        qrpaypal: '',
        qrstripe: '',
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setConfiguration({ ...configuration, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onSubmitUpdateConfiguration = (e) => {
        e.preventDefault();
        const errors = []
        if (configuration.name.trim().length < 1) {
            errors.push({ message: `nombre de la empresa es requerido` })
        }

        if (configuration.howmuchforreseller.toString().trim().length < 1) {
            errors.push({ message: `créditos mínimos para reseller es requerido` })
        }

        if (configuration.howmuchtimeforeditpassworddays.toString().trim().length < 1) {
            errors.push({ message: `días para poder restablecer la contraseña es requerido` })
        }

        if (errors.length === 0) {
            const configcurrent = {
                _id: configurations._id,
                appKey: configurations.appKey,
                name: configurations.name,
                logo: configurations.logo,
                slider1: configurations.sliders[0]?.url,
                slider2: configurations.sliders[1]?.url,
                slider3: configurations.sliders[2]?.url,
                slider4: configurations.sliders[3]?.url,
                slider5: configurations.sliders[4]?.url,
                login: configurations.login,
                register: configurations.register,
                buyservice: configurations.buyservice,
                buycredits: configurations.buycredits,
                changepassword: configurations.changepassword,
                changeavatar: configurations.changeavatar,
                maintenance: configurations.maintenance,
                howmuchforreseller: configurations.howmuchforreseller,
                howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
                canrecoverpassword: configurations.canrecoverpassword
            }

            if (JSON.stringify(configuration) !== JSON.stringify(configcurrent)) {
                configuration.sliders = configurations.sliders
                const formData = new FormData();
                formData.append('data', JSON.stringify(configuration));
                formData.append('current', JSON.stringify(configurations));

                formData.append('rawDataYape', JSON.stringify(convertToRaw(yapeEditor.getCurrentContent())));
                formData.append('rawDataPlin', JSON.stringify(convertToRaw(plinEditor.getCurrentContent())));
                formData.append('rawDataPagomovil', JSON.stringify(convertToRaw(pagomovilEditor.getCurrentContent())));
                formData.append('rawDataNequi', JSON.stringify(convertToRaw(nequiEditor.getCurrentContent())));
                formData.append('rawDataBanamex', JSON.stringify(convertToRaw(banamexEditor.getCurrentContent())));
                formData.append('rawDataYolopago', JSON.stringify(convertToRaw(yolopagoEditor.getCurrentContent())));
                formData.append('rawDataTigomoney', JSON.stringify(convertToRaw(tigomoneyEditor.getCurrentContent())));
                formData.append('rawDataYasta', JSON.stringify(convertToRaw(yastaEditor.getCurrentContent())));
                formData.append('rawDataTransferencia', JSON.stringify(convertToRaw(transferenciaEditor.getCurrentContent())));
                formData.append('rawDataSpin', JSON.stringify(convertToRaw(spinEditor.getCurrentContent())));
                formData.append('rawDataCashapp', JSON.stringify(convertToRaw(cashappEditor.getCurrentContent())));
                formData.append('rawDataVenmo', JSON.stringify(convertToRaw(venmoEditor.getCurrentContent())));
                formData.append('rawDataZelle', JSON.stringify(convertToRaw(zelleEditor.getCurrentContent())));
                formData.append('rawDataIzipay', JSON.stringify(convertToRaw(izipayEditor.getCurrentContent())));
                formData.append('rawDataMercadopago', JSON.stringify(convertToRaw(mercadopagoEditor.getCurrentContent())));
                formData.append('rawDataBinance', JSON.stringify(convertToRaw(binanceEditor.getCurrentContent())));
                formData.append('rawDataPaypal', JSON.stringify(convertToRaw(paypalEditor.getCurrentContent())));
                formData.append('rawDataStripe', JSON.stringify(convertToRaw(stripeEditor.getCurrentContent())));

                formData.append('image1', configuration.logo)
                formData.append('image2', configuration.slider1)
                formData.append('image3', configuration.slider2)
                formData.append('image4', configuration.slider3)
                formData.append('image5', configuration.slider4)
                formData.append('image6', configuration.slider5)
                formData.append('image7', configuration.qryape);
                formData.append('image8', configuration.qrplin);
                formData.append('image9', configuration.qrpagomovil);
                formData.append('image10', configuration.qrnequi);
                formData.append('image11', configuration.qrbanamex);
                formData.append('image12', configuration.qryolopago);
                formData.append('image13', configuration.qrtigomoney);
                formData.append('image14', configuration.qryasta);
                formData.append('image15', configuration.qrtransferencia);
                formData.append('image16', configuration.qrspin);
                formData.append('image17', configuration.qrcashapp);
                formData.append('image18', configuration.qrvenmo);
                formData.append('image19', configuration.qrzelle);
                formData.append('image20', configuration.qrizipay);
                formData.append('image21', configuration.qrmercadopago);
                formData.append('image22', configuration.qrbinance);
                formData.append('image23', configuration.qrpaypal);
                formData.append('image24', configuration.qrstripe);
                dispatch(updateConfiguration(formData))
            } else {
                message('No hubo ningún cambio.', 'error', 4)
            }

        } else {
            errors.map((err) => {
                message(err.message, 'error', 4)
            });
        }

    }

    useEffect(() => {
        if (isMutation.updated) {
            message(isMutation.message, 'success', 5)
            Socket.emit('uConfiguration', { newConfiguration: isMutation.extra.newConfiguration })
            setPreview({ ...preview, logo: '', slider1: '', slider2: '', slider3: '', slider4: '', slider5: '' })
            setConfiguration({ ...configuration, logo: '', slider1: '', slider2: '', slider3: '', slider4: '', slider5: '' })
            dispatch(SET_TO_DEFAULT_RESPONSE())
        }
    }, [isMutation.updated])

    return (
        <Wrapper>
            {/* HEADER PAGES */}
            <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Gestionar servidor
            </div>

            <div className="fixed mt-8 z-30 flex items-center border-b-2 border-b-brand-300 dark:border-b-brand-800 text-brand-950 dark:text-brand-50 gap-1 bg-brand-200 dark:bg-brand-950 pt-2">
                <div onClick={() => setSection('sistema')} className={`py-4 px-2 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'sistema' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Sistema</div>
                <div onClick={() => setSection('accesos')} className={`py-4 px-2 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'accesos' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Accesos</div>
                <div onClick={() => setSection('vip')} className={`py-4 px-2 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'vip' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>VIP</div>
                <div onClick={() => setSection('galeria')} className={`py-4 px-2 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'galeria' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Galeria</div>
                <div onClick={() => setSection('pasarela')} className={`py-4 px-2 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'pasarela' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Pasarelas</div>
            </div>

            <div className="flex flex-wrap mt-24 gap-4">
                <form className="w-full xl:w-8/12 p-[2px] md:p-[2px] lg:p-[0px]" onSubmit={onSubmitUpdateConfiguration}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5 mt-5">
                        {
                            section === 'sistema' &&
                            <>
                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">APP KEY</label>
                                    <input name="appKey" className="custom-style-input"
                                        autoComplete="off"
                                        disabled
                                        value={configuration.appKey}
                                        type="text"
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la empresa</label>
                                    <input autoFocus name="name" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.name}
                                        onChange={onChangeHandle}
                                        type="text"
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Créditos mínimos para reseller</label>
                                    <input name="howmuchforreseller" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.howmuchforreseller}
                                        onChange={onChangeHandle}
                                        type="number"
                                        min={0}
                                        onKeyPress={PermiteSoloNumeros}
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Días para poder restablecer contraseña</label>
                                    <input name="howmuchtimeforeditpassworddays" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.howmuchtimeforeditpassworddays}
                                        onChange={onChangeHandle}
                                        type="number"
                                        min={0}
                                        onKeyPress={PermiteSoloNumeros}
                                    />
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar usuarios</label>
                                    <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span class="sr-only">Info</span>
                                        <div>
                                            <span class="font-bold uppercase">Importante!</span> Se autoeliminarán los usuarios que no tengan créditos y no hayan ingresado durante la cantidad de días que configures.
                                        </div>
                                    </div>
                                    <div className="flex items-cente flex-wrap">
                                        <div className="w-[100px] mr-5">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeleteusers.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.autodeleteusers.autodelete}
                                                    onChange={() => setConfiguration({ ...configuration, autodeleteusers: { ...configuration.autodeleteusers, autodelete: !configuration.autodeleteusers.autodelete } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                            <input name="name" className="custom-style-input max-w-[90px]"
                                                autoComplete="off"
                                                required
                                                value={configuration.autodeleteusers.days}
                                                onChange={(e) => setConfiguration({ ...configuration, autodeleteusers: { ...configuration.autodeleteusers, days: e.target.value } })}
                                                type="text"
                                                onKeyPress={PermiteSoloNumeros}
                                                maxLength="3"
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar ventas bloqueadas</label>
                                        <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                            <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                            </svg>
                                            <span class="sr-only">Info</span>
                                            <div>
                                                <span class="font-bold uppercase">Importante!</span> Se autoeliminarán las ventas que se bloquean cuando expiran y se eliminarán después de la cantidad de días que configures y estén en la lista filtrada.
                                            </div>
                                        </div>
                                        <div className="flex items-cente flex-wrap">
                                            <div className="w-[100px] mr-5">
                                                <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeleteventas.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                                <div className="h-10 mt-3">
                                                    <Switch
                                                        value={configuration.autodeleteventas.autodelete}
                                                        onChange={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, autodelete: !configuration.autodeleteventas.autodelete } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                                <input name="name" className="custom-style-input max-w-[90px]"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.autodeleteventas.days}
                                                    onChange={(e) => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, days: e.target.value } })}
                                                    type="text"
                                                    onKeyPress={PermiteSoloNumeros}
                                                    maxLength="3"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Seleccionar filtro</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="subcategory"
                                                        className="flex items-center"
                                                        variant="outlined"
                                                    >
                                                        {!isMutationCategories.loading && subcategories.filter((fil) => fil.category.type === 'Streaming' && !configuration.autodeleteventas.targetIds.some(itemB => itemB === fil._id)).map(subcategory => (
                                                            <MenuItem onClick={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, targetIds: [...configuration.autodeleteventas.targetIds, subcategory._id] } })} value={subcategory._id} key={subcategory._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcategory.logo}`} className="mr-2 rounded-full" />{subcategory.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll bg-white dark:bg-brand-900 rounded-lg p-2">
                                        <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Lista de filtrados <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.autodeleteventas.targetIds.length}</span></label>
                                        {
                                            configuration.autodeleteventas.targetIds.map((item) => {
                                                let logo = ""
                                                let name = ""
                                                !isMutationCategories.loading && subcategories.find((subcat) => {
                                                    if (subcat._id === item) {
                                                        logo = subcat.logo
                                                        name = subcat.name
                                                    }
                                                })
                                                return (
                                                    <div key={item} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                        <div className="flex items-center justify-center">
                                                            <LazyLoadImage width={25} height={25} src={`/images/logos/${logo}`} className="mr-2 h-10 w-10 rounded-full object-cover" />
                                                        </div>
                                                        <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                            {name}
                                                        </div>
                                                        <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                            <Tooltip title="quitar" arrow={true}>
                                                                <Button onClick={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, targetIds: configuration.autodeleteventas.targetIds.filter((fil) => fil !== item) } })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                    <IoCloseCircle className="w-5 h-5 text-white" />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar cuentas expiradas</label>
                                        <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                            <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                            </svg>
                                            <span class="sr-only">Info</span>
                                            <div>
                                                <span class="font-bold uppercase">Importante!</span> Se autoeliminarán las cuentas cuando expiran y las ventas de esa cuenta, estas se eliminarán después de la cantidad de días que configures y estén en la lista filtrada.
                                            </div>
                                        </div>
                                        <div className="flex items-cente flex-wrap">
                                            <div className="w-[100px] mr-5">
                                                <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeletecuentas.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                                <div className="h-10 mt-3">
                                                    <Switch
                                                        value={configuration.autodeletecuentas.autodelete}
                                                        onChange={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, autodelete: !configuration.autodeletecuentas.autodelete } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                                <input name="name" className="custom-style-input max-w-[90px]"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.autodeletecuentas.days}
                                                    onChange={(e) => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, days: e.target.value } })}
                                                    type="text"
                                                    onKeyPress={PermiteSoloNumeros}
                                                    maxLength="3"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Seleccionar filtro</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="subcategory"
                                                        className="flex items-center"
                                                        variant="outlined"
                                                    >
                                                        {!isMutationCategories.loading && subcategories.filter((fil) => fil.category.type === 'Streaming' && !configuration.autodeletecuentas.targetIds.some(itemB => itemB === fil._id)).map(subcategory => (
                                                            <MenuItem onClick={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, targetIds: [...configuration.autodeletecuentas.targetIds, subcategory._id] } })} value={subcategory._id} key={subcategory._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcategory.logo}`} className="mr-2 rounded-full" />{subcategory.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll bg-white dark:bg-brand-900 rounded-lg p-2">
                                        <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Lista de filtrados <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.autodeletecuentas.targetIds.length}</span></label>
                                        {
                                            configuration.autodeletecuentas.targetIds.map((item) => {
                                                let logo = ""
                                                let name = ""
                                                !isMutationCategories.loading && subcategories.find((subcat) => {
                                                    if (subcat._id === item) {
                                                        logo = subcat.logo
                                                        name = subcat.name
                                                    }
                                                })
                                                return (
                                                    <div key={item} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                        <div className="flex items-center justify-center">
                                                            <LazyLoadImage width={25} height={25} src={`/images/logos/${logo}`} className="mr-2 h-10 w-10 rounded-full object-cover" />
                                                        </div>
                                                        <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                            {name}
                                                        </div>
                                                        <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                            <Tooltip title="quitar" arrow={true}>
                                                                <Button onClick={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, targetIds: configuration.autodeletecuentas.targetIds.filter((fil) => fil !== item) } })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                    <IoCloseCircle className="w-5 h-5 text-white" />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Redes sociales</label>
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Email</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.email}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, email: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Facebook</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.facebook}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, facebook: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tiktok</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.tiktok}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, tiktok: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Instagram</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.instagram}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, instagram: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Telegram</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.telegram}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, telegram: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Youtube</label>
                                                <input name="youtube" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration?.social?.youtube}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, youtube: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Whatsapp de soporte</label>
                                                <MuiTelInput
                                                    inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                                                    required
                                                    value={configuration.social.whatsapp}
                                                    defaultCountry={'PE'}
                                                    onChange={(newValue, info) => setConfiguration({ ...configuration, social: { ...configuration.social, whatsapp: newValue } })}
                                                    className="custom-style-input"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Localización</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration?.social?.location}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, location: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            section === 'accesos' &&
                            <>
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Ingreso? <span className="font-semibold">{configuration.login ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.login}
                                            onChange={() => setConfiguration({ ...configuration, login: !configuration.login })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Registro? <span className="font-semibold">{configuration.register ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.register}
                                            onChange={() => setConfiguration({ ...configuration, register: !configuration.register })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Comprar servicios? <span className="font-semibold">{configuration.buyservice ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.buyservice}
                                            onChange={() => setConfiguration({ ...configuration, buyservice: !configuration.buyservice })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Comprar créditos? <span className="font-semibold">{configuration.buycredits ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.buycredits}
                                            onChange={() => setConfiguration({ ...configuration, buycredits: !configuration.buycredits })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Cambiar contraseña? <span className="font-semibold">{configuration.changepassword ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.changepassword}
                                            onChange={() => setConfiguration({ ...configuration, changepassword: !configuration.changepassword })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Cambiar perfil? <span className="font-semibold">{configuration.changeavatar ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.changeavatar}
                                            onChange={() => setConfiguration({ ...configuration, changeavatar: !configuration.changeavatar })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Recuperar contraseña? <span className="font-semibold">{configuration.canrecoverpassword ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.canrecoverpassword}
                                            onChange={() => setConfiguration({ ...configuration, canrecoverpassword: !configuration.canrecoverpassword })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Mantenimiento pagina de usuarios? <span className="font-semibold">{configuration.maintenance ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">no</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.maintenance}
                                            onChange={() => setConfiguration({ ...configuration, maintenance: !configuration.maintenance })}
                                        />
                                    </div>
                                </div>

                            </>
                        }


                        {
                            section === 'vip' &&
                            <>
                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#553617] to-[#CD7F32] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP BRONCE
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip1.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip1.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                enabled: !configuration.vip.vip1.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip1.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip1.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip1.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip1.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip1.namefeature.length < 1 || configuration.vip?.vip1?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip1: {
                                                                    ...configuration.vip.vip1,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip1.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip1.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip1?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip1: {
                                                                                    ...configuration.vip.vip1,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip1.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#808080] to-[#C0C0C0] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP PLATA
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip2.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip2.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                enabled: !configuration.vip.vip2.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip2.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip2.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip2.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip2.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip2.namefeature.length < 1 || configuration.vip?.vip2?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip2: {
                                                                    ...configuration.vip.vip2,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip2.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip2.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip2?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip2: {
                                                                                    ...configuration.vip.vip2,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip2.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#816e00] to-[#FFD700] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP ORO
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip3.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip3.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                enabled: !configuration.vip.vip3.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip3.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip3.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip3.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip3.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip3.namefeature.length < 1 || configuration.vip?.vip3?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip3: {
                                                                    ...configuration.vip.vip3,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip3.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip3.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip3?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip3: {
                                                                                    ...configuration.vip.vip3,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip3.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }


                        {
                            section === 'galeria' &&
                            <>
                                <div className="w-full relative lg:col-span-2">
                                    <label for="first_name" class="block mb-4 text-base font-medium text-brand-900 dark:text-white">Logo de la empresa</label>
                                    <div className="grid grid-cols-1">
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="logo" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Logo</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.logo}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[90px] max-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.logo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full relative lg:col-span-2">
                                    <label for="first_name" class="block mb-4 text-base font-medium text-brand-900 dark:text-white">Sliders</label>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider1" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 1</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider1" accept="image/png,image/jpeg,image/webp" type="file" name="slider1" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[0].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider1 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider1} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider2" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 2</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider2" accept="image/png,image/jpeg,image/webp" type="file" name="slider2" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[1].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider2 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider3" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 3</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider3" accept="image/png,image/jpeg,image/webp" type="file" name="slider3" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[2].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider3 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider3} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider4" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 4</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider4" accept="image/png,image/jpeg,image/webp" type="file" name="slider4" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[3].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider4 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider4} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider5" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 5</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider5" accept="image/png,image/jpeg,image/webp" type="file" name="slider5" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[4].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider5 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider5} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {
                        section === 'pasarela' &&
                        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3 mb-5 mt-5">
                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.yape?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qryape &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qryape}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qryape" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qryape" accept="image/png,image/jpeg,image/webp" type="file" name="qryape" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">YAPE</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image yape}`}
                                    effect="opacity"
                                    src={`/images/paymethods/yape.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.yape?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.yape?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yape: { ...configuration.pasarelas.yape, enabled: !configuration.pasarelas.yape.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.yape?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.yape?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yape: { ...configuration.pasarelas.yape, auto: !configuration.pasarelas.yape.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Yape</label>
                                    <Editor
                                        editorState={yapeEditor}
                                        onEditorStateChange={setYapeEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.plin?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrplin &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrplin}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrplin" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrplin" accept="image/png,image/jpeg,image/webp" type="file" name="qrplin" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">PLIN</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image plin}`}
                                    effect="opacity"
                                    src={`/images/paymethods/plin.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.plin?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.plin?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, plin: { ...configuration.pasarelas.plin, enabled: !configuration.pasarelas.plin.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.plin?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.plin?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, plin: { ...configuration.pasarelas.plin, auto: !configuration.pasarelas.plin.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Plin</label>
                                    <Editor
                                        editorState={plinEditor}
                                        onEditorStateChange={setPlinEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.pagomovil?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrpagomovil &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrpagomovil}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrpagomovil" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrpagomovil" accept="image/png,image/jpeg,image/webp" type="file" name="qrpagomovil" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">PAGO MOVIL</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image pagomovil}`}
                                    effect="opacity"
                                    src={`/images/paymethods/pagomovil.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.pagomovil?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.pagomovil?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, pagomovil: { ...configuration.pasarelas.pagomovil, enabled: !configuration.pasarelas.pagomovil.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.pagomovil?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.pagomovil?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, pagomovil: { ...configuration.pasarelas.pagomovil, auto: !configuration.pasarelas.pagomovil.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Pagomovil</label>
                                    <Editor
                                        editorState={pagomovilEditor}
                                        onEditorStateChange={setPagoMovilEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.nequi?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrnequi &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrnequi}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrnequi" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrnequi" accept="image/png,image/jpeg,image/webp" type="file" name="qrnequi" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">NEQUI</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image nequi}`}
                                    effect="opacity"
                                    src={`/images/paymethods/nequi.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.nequi?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.nequi?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, nequi: { ...configuration.pasarelas.nequi, enabled: !configuration.pasarelas.nequi.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.nequi?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.nequi?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, nequi: { ...configuration.pasarelas.nequi, auto: !configuration.pasarelas.nequi.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Nequi</label>
                                    <Editor
                                        editorState={nequiEditor}
                                        onEditorStateChange={setNequiEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.banamex?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrbanamex &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrbanamex}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrbanamex" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrbanamex" accept="image/png,image/jpeg,image/webp" type="file" name="qrbanamex" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">BANAMEX</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image banamex}`}
                                    effect="opacity"
                                    src={`/images/paymethods/banamex.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.banamex?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.banamex?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, banamex: { ...configuration.pasarelas.banamex, enabled: !configuration.pasarelas.banamex.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.banamex?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.banamex?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, banamex: { ...configuration.pasarelas.banamex, auto: !configuration.pasarelas.banamex.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Banamex</label>
                                    <Editor
                                        editorState={banamexEditor}
                                        onEditorStateChange={setBanamexEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.yolopago?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qryolopago &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qryolopago}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qryolopago" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qryolopago" accept="image/png,image/jpeg,image/webp" type="file" name="qryolopago" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">YOLOPAGO</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image yolopago}`}
                                    effect="opacity"
                                    src={`/images/paymethods/yolopago.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.yolopago?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.yolopago?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yolopago: { ...configuration.pasarelas.yolopago, enabled: !configuration.pasarelas.yolopago.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.yolopago?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.yolopago?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yolopago: { ...configuration.pasarelas.yolopago, auto: !configuration.pasarelas.yolopago.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Yolopago</label>
                                    <Editor
                                        editorState={yolopagoEditor}
                                        onEditorStateChange={setYoloPagoEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.tigomoney?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrtigomoney &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrtigomoney}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrtigomoney" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrtigomoney" accept="image/png,image/jpeg,image/webp" type="file" name="qrtigomoney" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">TIGO MONEY</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image tigomoney}`}
                                    effect="opacity"
                                    src={`/images/paymethods/tigomoney.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.tigomoney?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.tigomoney?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, tigomoney: { ...configuration.pasarelas.tigomoney, enabled: !configuration.pasarelas.tigomoney.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.tigomoney?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.tigomoney?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, tigomoney: { ...configuration.pasarelas.tigomoney, auto: !configuration.pasarelas.tigomoney.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Yolopago</label>
                                    <Editor
                                        editorState={tigomoneyEditor}
                                        onEditorStateChange={setTigoMoneyEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.yasta?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qryasta &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qryasta}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qryasta" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qryasta" accept="image/png,image/jpeg,image/webp" type="file" name="qryasta" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">YASTA</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image yasta}`}
                                    effect="opacity"
                                    src={`/images/paymethods/yasta.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.yasta?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.yasta?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yasta: { ...configuration.pasarelas.yasta, enabled: !configuration.pasarelas.yasta.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.yasta?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.yasta?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, yasta: { ...configuration.pasarelas.yasta, auto: !configuration.pasarelas.yasta.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Yasta</label>
                                    <Editor
                                        editorState={yastaEditor}
                                        onEditorStateChange={setYastaEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.transferencia?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrtransferencia &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrtransferencia}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrtransferencia" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrtransferencia" accept="image/png,image/jpeg,image/webp" type="file" name="qrtransferencia" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">TRANSFERENCIA</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image transferencia}`}
                                    effect="opacity"
                                    src={`/images/paymethods/transferencia.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.transferencia?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.transferencia?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, transferencia: { ...configuration.pasarelas.transferencia, enabled: !configuration.pasarelas.transferencia.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.transferencia?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.transferencia?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, transferencia: { ...configuration.pasarelas.transferencia, auto: !configuration.pasarelas.transferencia.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Transferencia</label>
                                    <Editor
                                        editorState={transferenciaEditor}
                                        onEditorStateChange={setTransferenciaEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.spin?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrspin &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrspin}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrspin" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrspin" accept="image/png,image/jpeg,image/webp" type="file" name="qrspin" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">SPIN</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image spin}`}
                                    effect="opacity"
                                    src={`/images/paymethods/spin.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.spin?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.spin?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, spin: { ...configuration.pasarelas.spin, enabled: !configuration.pasarelas.spin.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.spin?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.spin?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, spin: { ...configuration.pasarelas.spin, auto: !configuration.pasarelas.spin.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Spin</label>
                                    <Editor
                                        editorState={spinEditor}
                                        onEditorStateChange={setSpinEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.cashapp?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrcashapp &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrcashapp}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrcashapp" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrcashapp" accept="image/png,image/jpeg,image/webp" type="file" name="qrcashapp" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">CASHAPP</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image cashapp}`}
                                    effect="opacity"
                                    src={`/images/paymethods/cashapp.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.cashapp?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.cashapp?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, cashapp: { ...configuration.pasarelas.cashapp, enabled: !configuration.pasarelas.cashapp.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.cashapp?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.cashapp?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, cashapp: { ...configuration.pasarelas.cashapp, auto: !configuration.pasarelas.cashapp.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de CashApp</label>
                                    <Editor
                                        editorState={cashappEditor}
                                        onEditorStateChange={setCashAppEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.venmo?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrvenmo &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrvenmo}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrvenmo" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrvenmo" accept="image/png,image/jpeg,image/webp" type="file" name="qrvenmo" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">VENMO</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image venmo}`}
                                    effect="opacity"
                                    src={`/images/paymethods/venmo.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.venmo?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.venmo?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, venmo: { ...configuration.pasarelas.venmo, enabled: !configuration.pasarelas.venmo.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.venmo?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.venmo?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, venmo: { ...configuration.pasarelas.venmo, auto: !configuration.pasarelas.venmo.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Venmo</label>
                                    <Editor
                                        editorState={venmoEditor}
                                        onEditorStateChange={setVenmoEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full md:w-[calc(33.3%-5px)] relative bg-brand-300 dark:bg-brand-900 p-3 md:col-span-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.zelle?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrzelle &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrzelle}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrzelle" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrzelle" accept="image/png,image/jpeg,image/webp" type="file" name="qrzelle" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">ZELLE</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image zelle}`}
                                    effect="opacity"
                                    src={`/images/paymethods/zelle.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.zelle?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.zelle?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, zelle: { ...configuration.pasarelas.zelle, enabled: !configuration.pasarelas.zelle.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.zelle?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.zelle?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, zelle: { ...configuration.pasarelas.zelle, auto: !configuration.pasarelas.zelle.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Zelle</label>
                                    <Editor
                                        editorState={zelleEditor}
                                        onEditorStateChange={setZelleEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.izipay?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrizipay &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrizipay}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrizipay" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrizipay" accept="image/png,image/jpeg,image/webp" type="file" name="qrizipay" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">IZIPAY</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image izipay}`}
                                    effect="opacity"
                                    src={`/images/paymethods/izipay.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.izipay?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.izipay?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, enabled: !configuration.pasarelas.izipay.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.izipay?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={false}
                                            value={configuration?.pasarelas?.izipay?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, auto: !configuration.pasarelas.izipay.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Public Key</label>
                                    <input name="public_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.izipay?.accountdata?.public_key}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, public_key: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">ID Tienda</label>
                                    <input name="id_tienda" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.izipay?.accountdata?.id_tienda}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, id_tienda: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Clave</label>
                                    <input name="clave" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.izipay?.accountdata?.clave}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, clave: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password</label>
                                    <input name="password" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.izipay?.accountdata?.password}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, password: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">CLAVE HMAC SHA256</label>
                                    <input name="clave_hmac_sha_256" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.izipay?.accountdata?.clave_hmac_sha_256}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, izipay: { ...configuration.pasarelas.izipay, clave_hmac_sha_256: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de IziPay</label>
                                    <Editor
                                        editorState={izipayEditor}
                                        onEditorStateChange={setIziPayEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.mercadopago?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrmercadopago &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrmercadopago}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrmercadopago" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrmercadopago" accept="image/png,image/jpeg,image/webp" type="file" name="qrmercadopago" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">MERCADOPAGO</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image mercadopago}`}
                                    effect="opacity"
                                    src={`/images/paymethods/mercadopago.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.mercadopago?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.mercadopago?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, mercadopago: { ...configuration.pasarelas.mercadopago, enabled: !configuration.pasarelas.mercadopago.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.mercadopago?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={false}
                                            value={configuration?.pasarelas?.mercadopago?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, mercadopago: { ...configuration.pasarelas.mercadopago, auto: !configuration.pasarelas.mercadopago.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">API Key</label>
                                    <input name="api_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.mercadopago?.accountdata?.api_key}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, mercadopago: { ...configuration.pasarelas.mercadopago, api_key: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Secret Key</label>
                                    <input name="secret_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.mercadopago?.accountdata?.secret_key}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, mercadopago: { ...configuration.pasarelas.mercadopago, secret_key: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de MercadoPago</label>
                                    <Editor
                                        editorState={mercadopagoEditor}
                                        onEditorStateChange={setMercadoPagoEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.binance?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrbinance &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrbinance}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrbinance" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrbinance" accept="image/png,image/jpeg,image/webp" type="file" name="qrbinance" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">BINANCE</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image binance}`}
                                    effect="opacity"
                                    src={`/images/paymethods/binance.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.binance?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.binance?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, binance: { ...configuration.pasarelas.binance, enabled: !configuration.pasarelas.binance.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.binance?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={false}
                                            value={configuration?.pasarelas?.binance?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, binance: { ...configuration.pasarelas.binance, auto: !configuration.pasarelas.binance.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">API Key</label>
                                    <input name="api_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.binance?.accountdata?.api_key}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, binance: { ...configuration.pasarelas.binance, api_key: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">API Secret</label>
                                    <input name="api_secret" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.binance?.accountdata?.api_secret}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, binance: { ...configuration.pasarelas.binance, api_secret: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Merchant ID</label>
                                    <input name="merchant_id" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.binance?.accountdata?.merchant_id}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, binance: { ...configuration.pasarelas.binance, merchant_id: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de MercadoPago</label>
                                    <Editor
                                        editorState={binanceEditor}
                                        onEditorStateChange={setBinanceEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.paypal?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrpaypal &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrpaypal}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrpaypal" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrpaypal" accept="image/png,image/jpeg,image/webp" type="file" name="qrpaypal" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">PAYPAL</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image paypal}`}
                                    effect="opacity"
                                    src={`/images/paymethods/paypal.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.paypal?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration?.pasarelas?.paypal?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, paypal: { ...configuration.pasarelas.paypal, enabled: !configuration.pasarelas.paypal.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.paypal?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={false}
                                            value={configuration?.pasarelas?.paypal?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, paypal: { ...configuration.pasarelas.paypal, auto: !configuration.pasarelas.paypal.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Client ID</label>
                                    <input name="client_id" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.paypal?.accountdata?.client_id}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, paypal: { ...configuration.pasarelas.paypal, client_id: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Client Secret</label>
                                    <input name="client_secret" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.paypal?.accountdata?.client_secret}
                                        type="text"
                                        onChange={(e) => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, paypal: { ...configuration.pasarelas.binance, client_secret: e.target.value } } })}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Paypal</label>
                                    <Editor
                                        editorState={paypalEditor}
                                        onEditorStateChange={setPaypalEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="w-full relative bg-brand-300 dark:bg-brand-900 p-3">
                                <div className="absolute top-2 right-2 w-[95px] h-[120px] overflow-hidden">
                                    <div className="relative w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]">
                                        <div className="absolute top-0 left-0 z-10">
                                            <LazyLoadImage
                                                className="object-cover w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]] z-24"
                                                alt={`No image`}
                                                effect="opacity"
                                                src={`/images/logos/${configuration?.pasarelas?.stripe?.accountdata?.qr}`}
                                            />
                                        </div>
                                        <div className="absolute top-0 left-0 z-20">
                                            {preview.qrstripe &&
                                                <div className="">
                                                    <LazyLoadImage
                                                        className="object-contain rounded-xs w-[95px] h-[95px] min-w-[95px] min-h-[95px] max-w-[95px] max-h-[95px]"
                                                        alt={`No image`}
                                                        effect="opacity"
                                                        src={preview.qrstripe}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="w-full h-[25px]">
                                        <label htmlFor="qrstripe" class="flex items-center justify-center w-full h-[25px] cursor-pointer bg-lightPrimary dark:darkPrimary hover:bg-lightPrimary/80 dark:hover:darkPrimary/80">
                                            <div class="flex items-center justify-center h-[25px] text-sm">
                                                <FaUpload className="mr-2" />  Subir QR
                                            </div>
                                            <input id="qrstripe" accept="image/png,image/jpeg,image/webp" type="file" name="qrstripe" class="hidden" onChange={imageHandle} />

                                        </label>

                                    </div>
                                </div>
                                <div className="text-brand-900 dark:text-brand-100 text-xl">STRIPE</div>
                                <LazyLoadImage
                                    className="z-10 w-[45px] h-[45px] rounded-full flex"
                                    alt={`No image stripe}`}
                                    effect="opacity"
                                    src={`/images/paymethods/stripe.webp`}
                                />
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{configuration?.pasarelas?.stripe?.enabled ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.stripe?.enabled}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, stripe: { ...configuration.pasarelas.stripe, enabled: !configuration.pasarelas.stripe.enabled } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Automático ? <span className="font-semibold">{configuration?.pasarelas?.stripe?.auto ? "Si" : "No"}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            disabled={true}
                                            value={configuration?.pasarelas?.stripe?.auto}
                                            onChange={() => setConfiguration({ ...configuration, pasarelas: { ...configuration.pasarelas, stripe: { ...configuration.pasarelas.stripe, auto: !configuration.pasarelas.stripe.auto } } })}
                                        />
                                    </div>
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">API Key</label>
                                    <input name="api_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.stripe?.accountdata?.api_key}
                                        type="text"
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Secret Key</label>
                                    <input name="secret_key" className="custom-style-input"
                                        autoComplete="off"
                                        value={configuration?.pasarelas?.stripe?.accountdata?.secret_key}
                                        type="text"
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Datos de Stripe</label>
                                    <Editor
                                        disabled={true}
                                        editorState={stripeEditor}
                                        onEditorStateChange={setStripeEditor}
                                        placeholder="Escribe aquí..."
                                        toolbarHidden={true}
                                        editorStyle={{
                                            width: '100%',
                                            height: '100px',
                                            maxHeight: '200px',
                                            border: '1px solid #ccc',
                                            padding: '5px',
                                            textAlign: 'center',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="flex m-auto md:w-12/12 w-full">
                        <Button
                            type="submit"
                            disabled={isMutation.uloading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {isMutation.uloading ? <Spinner /> : <IoReload size={24} className="mr-2" />}
                            {isMutation.uloading ? 'Guardando...' : 'ACTUALIZAR CONFIGURACION'}
                        </Button>
                    </div>
                </form>


            </div >

        </Wrapper >
    )
}
export default Configuration;