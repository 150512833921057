import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
import { BACKEND } from '../../../utils/Constants';
const API_URL = `${BACKEND}/api/category/`;

const createCategory = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getCategories = async () => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getSubCategories = async () => {
    const response = await axios.get(API_URL + 'subcategories', {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const deleteCategory = async (id) => {
    const response = await axios.delete(API_URL + 'category/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const deleteSubCategory = async (id) => {
    const response = await axios.delete(API_URL + 'subcategory/' + id, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const updateCategory = async (formData) => {
    const response = await axios.patch(API_URL + 'category', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const updateSubCategory = async (formData) => {
    const response = await axios.patch(API_URL + 'subcategory', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const categoryService = {
    createCategory,
    getCategories,
    getSubCategories,
    updateCategory,
    updateSubCategory,
    deleteCategory,
    deleteSubCategory
};

export default categoryService;
