import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
import { BACKEND } from '../../../utils/Constants';
const API_URL = `${BACKEND}/api/venta/`;

// Get all categories

const create = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const completarPedido = async (formData) => {
    const response = await axios.post(API_URL, formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const transferirPedido = async (formData) => {
    const response = await axios.patch(API_URL + 'transferirpedido', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const reembolsarPedido = async (formData) => {
    const response = await axios.patch(API_URL + 'reembolsarpedido', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const renew = async (formData) => {
    const response = await axios.patch(API_URL + 'renew', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const suspendedById = async (formData) => {
    const response = await axios.patch(API_URL + 'suspended', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const bloquearVentasByCorreoId = async (formData) => {
    const response = await axios.patch(API_URL + 'bloquearbycorreoid', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getAll = async () => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `ventasbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getByPageByUserId = async (query) => {
    const response = await axios.get(API_URL + `ventasbyuserId${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const getByCorreoId = async (correoid) => {
    const response = await axios.get(API_URL + 'ventasbycorreoid/' + correoid, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const del = async (id, type) => {
    const response = await axios.delete(API_URL + 'venta/' + id + '/' + type, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const update = async (formData) => {
    const response = await axios.patch(API_URL + 'venta', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const changeAutorenewService = async (formData) => {
    const response = await axios.patch(API_URL + 'autorenew', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const rejectedOrLockVenta = async (formData) => {
    const response = await axios.patch(API_URL + 'rejectedorlockventa', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const updateExpirationService = async (formData) => {
    const response = await axios.patch(API_URL + 'updateexpiration', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const completarPedidoConEmail = async (formData) => {
    const response = await axios.patch(API_URL + 'completarpedidoconemail', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const ventaService = {
    create,
    completarPedido,
    transferirPedido,
    reembolsarPedido,
    renew,
    suspendedById,
    bloquearVentasByCorreoId,
    getAll,
    getBypage,
    getByPageByUserId,
    getByCorreoId,
    contador,
    update,
    del,
    updateExpirationService,
    rejectedOrLockVenta,
    changeAutorenewService,
    completarPedidoConEmail
};

export default ventaService;
