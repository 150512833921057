import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ErrorPage = () => {
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const timer = setTimeout(() => {
            if (location.pathname.includes("admin")) {
                navigate("/admin/dashboard"); 
            } else {
                navigate("/");
            }
        }, 5000); 

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
            <h1 className="text-9xl font-bold text-red-500">404</h1>
            <h2 className="text-3xl font-semibold text-gray-800 mt-4">Página no encontrada 😢</h2>
            <p className="text-gray-600 mt-2">
                Lo sentimos, la página que buscas no existe.
            </p>
            <p className="text-gray-500 mt-2">Redirigiendo en 5 segundos...</p>
        </div>
    );
};

export default ErrorPage;
