// App.js
import { Pagination, PaginationItem, Stack, TextField, Link, Tooltip, IconButton, Button } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaClipboard, FaRegCalendarPlus, FaShippingFast, FaTrashAlt, FaUser } from 'react-icons/fa';
import { IoAlarm, IoBagCheck, IoBan, IoBuild, IoCart, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEye, IoFingerPrint, IoLockClosed, IoLockOpen, IoLogoWhatsapp, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoRocketSharp, IoSave, IoSearch, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import SpinnerData from '../../components/SpinnerData';
import { CalcularExpiracion, CalcularTiempoTranscurrido, currencyFormat, message, setUnixToTimeFormat } from '../../utils/Funciones';
import draftToHtml from "draftjs-to-html";
import Swal from "sweetalert2";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Wrapper from './Wrapper';
import moment from 'moment';
import { MdAlternateEmail, MdAvTimer, MdClose, MdDelete, MdDevices, MdLabel, MdMail, MdOutgoingMail, MdOutlineTimerOff, MdPhoneIphone, MdTimer, MdTimerOff, MdVerified, MdWarning } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import Spinner2 from '../../components/Spinner2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useDispatch, useSelector } from 'react-redux';
import { uriSerialized } from '../../utils/uriSerialized';
import { getBypage, contador as getContador, SET_TO_DEFAULT_RESPONSE, del, completarPedido, rechazarPedido } from '../../store/features/slices/gcventaSlice';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import { FaBan, FaBarcode, FaEye, FaKey, FaList, FaMobileScreen, FaMoneyBillTransfer, FaRegUser, FaRocket, FaRulerVertical, FaScreenpal, FaShopify, FaSquareCheck } from 'react-icons/fa6';
import { GiShoppingBag } from "react-icons/gi";
import Modal from '../../components/modal/Modal';

const GiftCardsOrders = ({ }) => {
  const dispatch = useDispatch();

  const [curTime, SetCurTime] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [infopedido, setInfopedido] = useState('')
  const [limit, setLimit] = useState(30);
  const [answerPedido, setAnswerPedido] = useState({ open: false, observation: "", namesubGC: "", data: null });
  const [verdetalles, SetVerDetalles] = useState({
    open: false,
    ventaId: "",
    categoryname: "",
    subcategoryname: "",
    subcategorylogo: "",
    username: "",
    email: "",
    gcdata: [],
    copied: false
  })

  const {
    ventasByPage,
    isMutation,
    contador,
    totalFilter,
    total,
    totalPages
  } = useSelector((state) => state.gcventaReducer);

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(getContador())
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
    const queryOptions = {
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getBypage(query))
  }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

  useEffect(() => {
    if (isMutation.updated) {
      message(isMutation.message, 'success', 5)
      answerPedido.open && setAnswerPedido({ ...answerPedido, open: false })
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated])

  useEffect(() => {
    dispatch(getContador())
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      SetCurTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  const handleOpenDetalles = (venta) => {
    SetVerDetalles({ ...verdetalles, open: true, ventaId: venta.ventaId, categoryname: venta.category.name, subcategoryname: venta.subcategory.name, subcategorylogo: venta.subcategory.logo, username: venta.user.name, email: venta.user.email, gcdata: venta.gcdata })
  }

  const openAnswerRejectedPedido = (venta) => {
    setAnswerPedido({ ...answerPedido, open: true, namesubGC: venta.subcategory.name, data: venta })
  }

  const handleCompletarPedido = (venta) => {
    const PedidoData = {
      _id: venta._id,
      catId: venta.category._id,
      subcatId: venta.subcategory._id,
      userId: venta.user._id,
      totalamount: venta.totalamount,
      QtyGC: venta.quantity,
      isADMIN: true,
      action: 'complete'
    }
    dispatch(completarPedido(PedidoData));
  }

  const handleRejectedPedido = (venta) => {
    const PedidoData = {
      _id: venta._id,
      catId: venta.category._id,
      subcatId: venta.subcategory._id,
      userId: venta.user._id,
      totalamount: venta.totalamount,
      QtyGC: venta.quantity,
      isADMIN: true,
      action: 'rejected',
      observation: answerPedido.observation
    }
    dispatch(rechazarPedido(PedidoData));
  }

  const deleteVenta = (id) => {
    alertatresbotones('eliminar pedido', 'Click en el boton borrar este pedido para eliminar y click borrar todo para borrar todos los pedidos bloqueados y rechazados.', 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(del({ id, type: 'uno' }));
      } else if (res_alert === false) {
        dispatch(del({ id, type: 'todos' }));
      }
    })
  }

  const alertatresbotones = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar este pedido',
      denyButtonText: `Borrar todo`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        callback(true)
      } else if (result.isDenied) {
        callback(false)
      }
    })
  }

  const onCopy = (text) => {
    SetVerDetalles({ ...verdetalles, copied: true });
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const HandleChangeFilterPedidos = async (state) => {
    setSearchTerm(state)
    SetPedidosByState(state)
    setCurrentPage(1);
  }

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    HandleChangeFilterPedidos("")
    document.getElementById("search").value = "";
  };

  return (

    <Wrapper>
      <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
        Pedidos tarjetas de regalo
        <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
          {!contador.loading && contador.total}
        </div>
      </div>
      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
        <div className="!w-9/12">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar pedidos tarjetas de regalo...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
          {!isMutation.loading ?
            <>
              <div className="hidden md:block">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="large"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-brand-200 dark:bg-brand-950"
                  />
                </Stack>
              </div>
              <div className="block md:hidden">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-brand-200 dark:bg-brand-950"
                  />
                </Stack>
              </div>
            </> : <Spinner />
          }
        </div>
        <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">

          <Tooltip title="Click aquí para filtrar pedidos pendientes" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("pending")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "pending" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-yellow-500 p-2 md:p-3 rounded-lg`}>
              <div className="flex inline-flex items-center"><FaShippingFast className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.pending : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar pedidos rechazados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("rejected")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "rejected" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-700 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.rejected : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar todos los pedidos" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBagCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.total : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

        </div>
      </div>

      <div className='w-full'>
        <Modal
          open={verdetalles.open}
          onClose={() => SetVerDetalles({ ...verdetalles, open: false, ventaId: "", categoryname: "", subcategoryname: "", subcategorylogo: "", username: "", email: "", gcdata: [] })}
          headerTitle={`Detalles del pedido`}
          size='base'
        >
          <div className="relative w-full">
            <span className="p-2 text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-lg bg-brand-200 dark:bg-brand-800">
              {verdetalles.ventaId}
            </span>
            <div className="w-full text-brand-900 dark:text-brand-200 mt-3">
              <div className="grid grid-wrap w-full md:w-9/12">
                <div className="flex inline-flex items-center">
                  <LazyLoadImage
                    className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                    alt={`No image ${verdetalles.subcategoryname}`}
                    effect="opacity"
                    src={`/images/logos/${verdetalles.subcategorylogo}`}
                  />
                  <div className="grid self-center">
                    <div className="ml-2 text-base font-semibold flex inline-flex">{verdetalles.username}</div>
                    <div className="ml-2 text-sm font-semibold">{verdetalles.categoryname}</div>
                    <div className="ml-2 text-sm font-semibold">{verdetalles.subcategoryname}</div>
                  </div>
                </div>
              </div>
              <div className='bg-brand-50 dark:bg-brand-950 w-full text-center mt-5'>
                <div className='text-lg bg-brand-300 dark:bg-brand-800 p-5 w-full text-center flex items-center justify-center mb-2'>Tarjetas de regalo <div className='bg-brand-200 dark:bg-brand-900 p-2 rounded-full w-8 h-8 ml-2 flex items-center justify-center'>{verdetalles.gcdata.length}</div></div>
                {
                  verdetalles.gcdata.map((gc, index) => {
                    return (
                      <div className="relative flex w-full flex-wrap bg-brand-200 dark:bg-brand-900 p-3 mb-4">
                        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-[50px] grid text-xl self-center">
                          {index + 1}
                        </div>
                        <div className="w-full md:w-[calc(50%-50px)] lg:w-[calc(50%-50px)] grid">
                          <div className="flex items-center justify-center uppercase text-sm">Precio unitario</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center">
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaMoneyBillTransfer size={24} className="mr-2" />{currencyFormat(gc.priceunit)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full md:w-6/12 lg:w-6/12 grid mt-3 md:mt-0 lg:mt-0">
                          <div className="flex items-center justify-center uppercase text-sm">Código</div>
                          <div className="flex items-center justify-center">
                            <div className="flex inline-flex items-center justify-center">
                              <div class="w-full text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2">
                                <FaKey size={24} className="mr-2 w-[35px] min-w-[35px]" />
                                <div className="w-[calc(100%-60px)] text-base">{(gc.gcId?.code).substr(0, gc.gcId?.code.length - 4)}...</div>
                                <div className="ml-3 w-[30px] flex justify-end">
                                  <Tooltip title="copiar" arrow={true}>
                                    <div>
                                      <CopyToClipboard text={gc.gcId?.code} onCopy={() => onCopy('código copiado')}>
                                        <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                      </CopyToClipboard>
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={answerPedido.open}
          onClose={() => setAnswerPedido({ ...answerPedido, open: false, observation: "", namesubGC: "", data: null })}
          headerTitle={`Rechazar pedido`}
          size="base"
        >
          <div className="relative w-full">
            <div className="w-full text-brand-900 dark:text-brand-200">
              <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full">
                <div className="md:w-6/12 w-full grid">
                  <div className="flex items-center justify-center uppercase text-sm">Código de pedido</div>
                  <div className="flex items-center justify-center">
                    <div className="flex inline-flex items-center">
                      <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2"><FaBarcode size={24} className="mr-2" />{answerPedido.data?.ventaId}</div>
                    </div>
                  </div>
                </div>
                <div className="md:w-6/12 w-full grid">
                  <div className="flex items-center justify-center uppercase text-sm">Detalles</div>
                  <div className="flex items-center justify-center">
                    <div className="flex inline-flex items-center">
                      <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaList size={24} className="mr-2" />{answerPedido.data?.quantity} {answerPedido.namesubGC}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full mt-5'>
                <div className="w-full mb-5 mt-7">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Observación (max: 35 chars)</label>
                  <input autoFocus name="observation" className="custom-style-input"
                    autoComplete="off"
                    maxLength={35}
                    required
                    value={answerPedido.observation}
                    onChange={(e) => setAnswerPedido({ ...answerPedido, observation: e.target.value })}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-1">
              <Button
                onClick={() => handleRejectedPedido(answerPedido.data)}
                disabled={answerPedido.observation.trim().length > 0 ? false : true}
                sx={{
                  marginTop: 5,
                  "&.Mui-disabled": {
                    background: "#525252",
                    color: "#a3a3a3"
                  }
                }}
                type="buttom" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="error" startIcon={isMutation.uloading ? <Spinner w={'35px'} h={'35px'} /> : <IoBan size={24} />}>{isMutation.uloading ? 'Procesando...' : `Rechazar`} </Button>
            </div>
          </div>
        </Modal>

      </div>

      <div className="relative w-full">
        {!isMutation.loading ? ventasByPage.length > 0 ?
          <div className="flex flex-wrap mt-[310px] md:mt-[335px] lg:mt-[290px]">
            {ventasByPage.map((venta) => {

              return (
                <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-lg animation`} key={venta.ventaId}>
                  <label className="absolute -top-8 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900">
                    <span className="text-[12px]">{venta.ventaId}</span>
                  </label>
                  <div className="flex flex-wrap items-center w-full md:w-full lg:w-4/12">
                    <div className="flex inline-flex items-center">
                      <LazyLoadImage
                        className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                        alt={`No image ${venta.subcategory?.name}`}
                        effect="opacity"
                        src={`/images/logos/${venta.subcategory?.logo}`}
                      />
                    </div>
                    <div className="grid gap-1 self-center">
                      <div className="ml-2 text-sm uppercase font-bold truncate ...">{venta.subcategory?.name}</div>
                      <div className="ml-2 flex items-center text-xs truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {venta.user?.email}
                        <span className="ml-3">
                          <Tooltip title="copiar" arrow={true}>
                            <div>
                              <CopyToClipboard text={venta.user.email} onCopy={() => onCopy('correo copiado')}>
                                <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                              </CopyToClipboard>
                            </div>
                          </Tooltip>
                        </span>
                      </div>
                      <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {venta.user?.name}</div>
                      <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', venta.created_at)}</div>
                    </div>
                  </div>

                  <div className="relative md:flex grid grid-cols-1 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-6/12 mt-5 md:mt-4 lg:mt-0">
                    <div className="md:w-4/12 w-full grid">
                      <div className="flex items-center justify-center uppercase text-sm">Cantidad</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2"><FaRulerVertical size={24} className="mr-2" />{venta.quantity}</div>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-4/12 w-full grid">
                      <div className="flex items-center justify-center uppercase text-sm">Costo total</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaMoneyBillTransfer size={24} className="mr-2" />{currencyFormat(venta.totalamount)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-4/12 w-full grid">
                      <div className="flex items-center justify-center uppercase text-sm">Estado</div>
                      <div className="flex items-center justify-center">
                        {venta.state === "Completed" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                        {venta.state === "Pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />Pendiente</div>}
                        {venta.state === "Rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />Rechazado</div>}
                      </div>

                      {venta.observations !== "" &&
                        <span className='text-xs text-center'>
                          {venta.observations}
                        </span>
                      }

                    </div>
                  </div>

                  <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-2/12 mt-5 md:mt-5 lg:mt-0">
                    <Tooltip title="ver detalles" arrow={true}>
                      <Button onClick={() => handleOpenDetalles(venta)} disabled={(venta.state === "Rejected" || venta.state === "Pending") ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-blue-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-blue-600 active:!bg-blue-700 dark:!bg-blue-400 dark:!text-white dark:hover:!bg-blue-300 dark:active:!bg-blue-200">
                        <FaEye className="w-5 h-5 text-white" />
                      </Button>
                    </Tooltip>

                    <Tooltip title="completar pedido" arrow={true}>
                      <Button onClick={() => handleCompletarPedido(venta)} disabled={(venta.state === "Rejected" || isMutation.uloading || venta.state === "Completed") ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                        <FaRocket className="w-5 h-5 text-white" />
                      </Button>
                    </Tooltip>

                    <Tooltip title="rechazar pedido" arrow={true}>
                      <Button onClick={() => openAnswerRejectedPedido(venta)} disabled={venta.state !== 'Pending' ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                        <FaBan className="w-5 h-5 text-white" />
                      </Button>
                    </Tooltip>

                    <Tooltip title="eliminar" arrow={true}>
                      <Button onClick={() => deleteVenta(venta._id)} disabled={(venta.state !== 'Rejected') ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                        <FaTrashAlt className="w-5 h-5 text-white" />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )
            })}
          </div> : <div className="alert-danger font-semibold mt-[310px] md:mt-[335px] lg:mt-[290px]">Sin resultados en la búsqueda! o no existen pedidos registradas</div> : <div className="mt-[310px] md:mt-[335px] lg:mt-[290px]"><SpinnerData>Cargando pedidos, por favor espere...</SpinnerData></div>}
      </div>
    </Wrapper>
  );
};

export default GiftCardsOrders;
